import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { ApplicationVars, Breakpoints, Levels, RoutePath, Routes } from './constants';
import { AlphaTypes } from './pages/BrowseAlphaPage/BrowseAlphaPage-constants';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ArticlePageContainer from './pages/ArticlePage/ArticlePage.lazy';
import BrowseAlphaPage from './pages/BrowseAlphaPage/BrowseAlphaPage.lazy';
import BrowseAnimalPage from './pages/BrowseAnimalPage/BrowseAnimalPage.lazy';
import BrowseAtlasPage from './pages/BrowseAtlasPage/BrowseAtlasPage.lazy';
import BrowseGamePage from './pages/BrowseGamePage/BrowseGamePage.lazy';
import BrowseThemePage from './pages/BrowseThemePage/BrowseThemePage.lazy';
import BrowseVideoPage from './pages/BrowseVideoPage/BrowseVideoPage.lazy';
import ErrorPage from './pages/ErrorPage/ErrorPage.lazy';
import HomePage from './pages/HomePage/HomePage.lazy';
import HelpPageContainer from './pages/HelpPage/HelpPageContainer';
import LevelsPage from './pages/LevelsPage/LevelsPage.lazy';
import MyBritannicaPage from './pages/MyBritannicaPage/MyBritannicaPage.lazy';
import SearchResultsPage from './pages/SearchResultsPage/SearchResultsPage.lazy';
import SharedCollectionPage from './pages/SharedCollectionPage/SharedCollectionPage.lazy';
import useWithStore from './hooks/useWithStore.hook';
import useQuery from './hooks/useQuery';

import DeepLinkHome from './pages/DeepLinkPage/DeepLinkHome.lazy';
import SamplePage from './pages/SamplePage/SamplePage.lazy';
import TestPage from './pages/TestPage/TestPage.lazy';
import InstAuthRoute from './components/InstAuthRoute/InstAuthRoute';
import AnalyticsApi from './services/AnalyticsApi';

const AppRoutes = () => {
  const {
    store: { instAuth, individualAuth },
  } = useWithStore(state => ({
    instAuth: state.instAuth,
    individualAuth: state.individualAuth,
  }));

  const location = useLocation();
  const history = useHistory();

  const query = useQuery();

  const isLoading = !instAuth.responseReturned;

  const isInstAuth = instAuth.isAuthenticated;
  const userData = instAuth.instUserData;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('.pc') || queryParams.has('errorPerm')) {
      queryParams.delete('.pc');
      queryParams.delete('errorPerm');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  useEffect(() => {
    AnalyticsApi.writeStat(instAuth);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'route_message',
        Boat_ID:
          userData?.accountId ||
          parseInt(
            document.querySelector("meta[name='accountId']")?.getAttribute('content') ?? '0'
          ) ||
          'NotLoggedIn',
        Individual_ID:
          userData?.individual?.individualId ||
          parseInt(
            document.querySelector("meta[name='individualId']")?.getAttribute('content') ?? '0'
          ) ||
          'NotLoggedIn',
      });
    }
  }, [location.pathname]);

  if (query.get('target')) {
    window.location.href = query.get('target');

    return <></>;
  }

  return (
    <Switch>
      {/* Individual private routes */}
      <PrivateRoute
        path={`/${Levels.ELEMENTARY}${Routes.MY_BRITANNICA.path}`}
        component={MyBritannicaPage}
        level={Levels.ELEMENTARY}
        isIndividualAuth={individualAuth.isAuthenticated}
        userData={userData}
      />
      <PrivateRoute
        path={`/${Levels.MIDDLE}${Routes.MY_BRITANNICA.path}`}
        component={MyBritannicaPage}
        level={Levels.MIDDLE}
        isIndividualAuth={individualAuth.isAuthenticated}
        userData={userData}
      />
      <PrivateRoute
        path={`/${Levels.HIGH}${Routes.MY_BRITANNICA.path}`}
        component={MyBritannicaPage}
        level={Levels.HIGH}
        isIndividualAuth={individualAuth.isAuthenticated}
        userData={userData}
      />

      <Route
        path={Routes.SHARED_COLLECTION.path}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${Routes.SHARED_COLLECTION.path}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <SharedCollectionPage {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.MIDDLE}${Routes.SHARED_COLLECTION.path}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.MIDDLE}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <SharedCollectionPage {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.HIGH}${Routes.SHARED_COLLECTION.path}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.HIGH}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <SharedCollectionPage {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      <Route path={RoutePath.DEEPLINK_HOME} component={DeepLinkHome} />

      {/* Institutional auth routes */}

      <Route
        path={RoutePath.BROWSE_HELP}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_HELP}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HelpPageContainer {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.MIDDLE}${RoutePath.BROWSE_HELP}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.MIDDLE}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HelpPageContainer {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.HIGH}${RoutePath.BROWSE_HELP}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.HIGH}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HelpPageContainer {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      <Route
        exact
        path={RoutePath.HOME}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <LevelsPage {...routeProps} />
          </InstAuthRoute>
        )}
      />

      <Route
        exact
        path={`/${Levels.ELEMENTARY}${RoutePath.HOME}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.MIDDLE}${RoutePath.HOME}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.MIDDLE}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.HIGH}${RoutePath.HOME}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.HIGH}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      <Route
        path={RoutePath.MEDIA_OVERLAY}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        exact
        path={`/${Levels.ELEMENTARY}${RoutePath.MEDIA_OVERLAY}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.MIDDLE}${RoutePath.MEDIA_OVERLAY}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.MIDDLE}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.HIGH}${RoutePath.MEDIA_OVERLAY}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.HIGH}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <HomePage {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      <Route
        path={RoutePath.ARTICLE}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.ARTICLE}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <ArticlePageContainer {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.MIDDLE}${RoutePath.ARTICLE}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.MIDDLE}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <ArticlePageContainer {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        path={`/${Levels.HIGH}${RoutePath.ARTICLE}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.HIGH}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <ArticlePageContainer {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      <Route
        exact
        path={RoutePath.BROWSE_ATLAS}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        exact
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_ATLAS}`}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
            level={Levels.ELEMENTARY}
          >
            <BrowseAtlasPage {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.MIDDLE}${RoutePath.BROWSE_ATLAS}`}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
            level={Levels.MIDDLE}
          >
            <BrowseAtlasPage {...routeProps} level={Levels.MIDDLE} />
          </InstAuthRoute>
        )}
      />
      <Route
        exact
        path={`/${Levels.HIGH}${RoutePath.BROWSE_ATLAS}`}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
            level={Levels.HIGH}
          >
            <BrowseAtlasPage {...routeProps} level={Levels.HIGH} />
          </InstAuthRoute>
        )}
      />

      {/* Exclusive for Elementary */}
      <Route
        path={RoutePath.BROWSE_THEME}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_THEME}`}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            level={Levels.ELEMENTARY}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <MediaQuery maxWidth={Breakpoints.MD - 1}>
              {useWindow => (
                <BrowseThemePage {...routeProps} level={Levels.ELEMENTARY} useWindow={useWindow} />
              )}
            </MediaQuery>
          </InstAuthRoute>
        )}
      />

      {/* Exclusive for Elementary */}
      <Route
        path={RoutePath.BROWSE_GAMES}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_GAMES}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <BrowseGamePage {...routeProps} level={Levels.ELEMENTARY} />
          </InstAuthRoute>
        )}
      />

      {/* Exclusive for Elementary */}
      <Route
        path={RoutePath.BROWSE_VIDEOS}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_VIDEOS}`}
        render={routeProps => (
          <InstAuthRoute
            isLoading={isLoading}
            level={Levels.ELEMENTARY}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <MediaQuery maxWidth={Breakpoints.MD - 1}>
              {useWindow => <BrowseVideoPage {...routeProps} useWindow={useWindow} />}
            </MediaQuery>
          </InstAuthRoute>
        )}
      />

      {/* Exclusive for Elementary */}
      <Route
        path={RoutePath.BROWSE_ANIMALS}
        render={routeProps => (
          <Redirect
            to={{
              pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
              state: routeProps.location.state,
            }}
          />
        )}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_ANIMALS}`}
        render={routeProps => (
          <InstAuthRoute
            level={Levels.ELEMENTARY}
            isLoading={isLoading}
            isAuth={isInstAuth}
            userData={userData}
            redirectPath={routeProps.location.pathname}
          >
            <MediaQuery maxWidth={Breakpoints.MD - 1}>
              {isMobile => (
                <BrowseAnimalPage {...routeProps} level={Levels.ELEMENTARY} isMobile={isMobile} />
              )}
            </MediaQuery>
          </InstAuthRoute>
        )}
      />

      <Route
        path={RoutePath.BROWSE_ALPHA}
        render={routeProps =>
          AlphaTypes[routeProps.match.params.alphaType] ? (
            <Redirect
              to={{
                pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
                state: routeProps.location.state,
              }}
            />
          ) : (
            <Route component={ErrorPage} />
          )
        }
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.BROWSE_ALPHA}`}
        render={routeProps =>
          AlphaTypes[routeProps.match.params.alphaType] ? (
            <InstAuthRoute
              level={Levels.ELEMENTARY}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <MediaQuery maxWidth={Breakpoints.MD - 1}>
                {isMobile => (
                  <BrowseAlphaPage {...routeProps} level={Levels.ELEMENTARY} isMobile={isMobile} />
                )}
              </MediaQuery>
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          )
        }
      />

      <Route
        path={`/${Levels.MIDDLE}${RoutePath.BROWSE_ALPHA}`}
        render={routeProps =>
          AlphaTypes[routeProps.match.params.alphaType] ? (
            <InstAuthRoute
              level={Levels.MIDDLE}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <MediaQuery maxWidth={Breakpoints.MD - 1}>
                {isMobile => (
                  <BrowseAlphaPage {...routeProps} level={Levels.MIDDLE} isMobile={isMobile} />
                )}
              </MediaQuery>
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          )
        }
      />

      <Route
        path={`/${Levels.HIGH}${RoutePath.BROWSE_ALPHA}`}
        render={routeProps =>
          AlphaTypes[routeProps.match.params.alphaType] ? (
            <InstAuthRoute
              level={Levels.HIGH}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <MediaQuery maxWidth={Breakpoints.MD - 1}>
                {isMobile => (
                  <BrowseAlphaPage {...routeProps} level={Levels.HIGH} isMobile={isMobile} />
                )}
              </MediaQuery>
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          )
        }
      />

      {/* Check to make sure searchQuery exists before loading SearchResultsPageContainer */}
      <Route
        path={RoutePath.SEARCH}
        render={routeProps => {
          const { searchQuery } = routeProps.match.params;

          return searchQuery ? (
            <Redirect
              to={{
                pathname: `/${Levels.ELEMENTARY}${routeProps.location.pathname}`,
                state: routeProps.location.state,
              }}
            />
          ) : (
            <Route component={ErrorPage} />
          );
        }}
      />
      <Route
        path={`/${Levels.ELEMENTARY}${RoutePath.SEARCH}`}
        render={routeProps => {
          const { searchQuery } = routeProps.match.params;

          return searchQuery ? (
            <InstAuthRoute
              level={Levels.ELEMENTARY}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <SearchResultsPage
                {...routeProps}
                searchQuery={searchQuery}
                level={Levels.ELEMENTARY}
              />
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          );
        }}
      />
      <Route
        path={`/${Levels.MIDDLE}${RoutePath.SEARCH}`}
        render={routeProps => {
          const { searchQuery } = routeProps.match.params;

          return searchQuery ? (
            <InstAuthRoute
              level={Levels.MIDDLE}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <SearchResultsPage {...routeProps} searchQuery={searchQuery} level={Levels.MIDDLE} />
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          );
        }}
      />
      <Route
        path={`/${Levels.HIGH}${RoutePath.SEARCH}`}
        render={routeProps => {
          const { searchQuery } = routeProps.match.params;

          return searchQuery ? (
            <InstAuthRoute
              level={Levels.HIGH}
              isLoading={isLoading}
              isAuth={isInstAuth}
              userData={userData}
              redirectPath={routeProps.location.pathname}
            >
              <SearchResultsPage {...routeProps} searchQuery={searchQuery} level={Levels.HIGH} />
            </InstAuthRoute>
          ) : (
            <Route component={ErrorPage} />
          );
        }}
      />

      {/* Making sure we do not break any existing customer embedded widgets */}
      <Route
        exact
        path={RoutePath.SEARCH_WIDGET}
        render={() => {
          window.location.href = `${RoutePath.SEARCH_WIDGET}/anosiniciais.html`;
        }}
      />

      {/* Routes that only appear in dev mode */}
      {ApplicationVars.ENV !== 'production' && (
        <Route path={RoutePath.SAMPLE_PAGE} component={SamplePage} />
      )}
      {ApplicationVars.ENV !== 'production' && <Route path={RoutePath.TEST} component={TestPage} />}

      <Route component={ErrorPage} />
    </Switch>
  );
};

AppRoutes.propTypes = {
  match: PropTypes.shape(),
};

export default AppRoutes;
