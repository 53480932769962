import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from '../../../macros/lodash.macro';
import { ApplicationVars, RoutePath, SearchType } from '../../constants';
import ContentApi from '../../services/ContentApi';
import Utilities from '../../services/Utilities';
import SearchBox from './SearchBox';

class SearchBoxContainer extends Component {
  constructor(props) {
    super(props);

    const { hasDropdown, hasAutocomplete, match, level } = this.props;
    const { searchQuery, searchType } = match.params;

    this.state = {
      isSubmitDisabled: !searchQuery,
      searchType: searchType || SearchType.ARTICLES,
      suggestions: hasAutocomplete ? [] : null,
      value: Utilities.cleanSearchQuery(searchQuery),
    };

    this.dropdownOptions = hasDropdown
      ? [
          {
            value: SearchType.ARTICLES,
            displayValue: level.Elementary ? 'Enciclopédia Escolar' : 'Enciclopédia',
          },
          {
            value: SearchType.DICTIONARY,
            displayValue: 'Dicionário',
          },
        ]
      : null;

    this.onChange = this.onChange.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
    this.onSearchTypeChange = this.onSearchTypeChange.bind(this);
    this.onSearchSubmitHandler = this.onSearchSubmitHandler.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionsFetchRequested = debounce(
      this.onSuggestionsFetchRequested.bind(this),
      ApplicationVars.DEBOUNCE_RATE
    );
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { searchType } = nextProps.match.params;
    const { searchType: prevSearchType } = prevState;

    // make sure to update searchType state to match searchType param.
    if (searchType && prevSearchType !== searchType) {
      return {
        searchType,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.key !== prevLocation.key) {
      this.onRouteChange();
    }
  }

  // --- Autocomplete props

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  async onSuggestionsFetchRequested({ value, reason }) {
    // don't do anything when user focuses on searchbox input
    if (reason === 'input-focused') {
      return;
    }

    const { searchType } = this.state;
    const { level } = this.props;

    this.setState({
      suggestions: await ContentApi.fetchAutocompleteSuggestions(searchType, value, level),
    });
  }

  onSuggestionSelected(event, { suggestion }) {
    const { searchType } = this.state;
    const { history, level } = this.props;

    /*
      ** Only for autocompleted Articles at the moment **
      We want to route the user to a specific article
      that they selected from the autocomplete suggestions dropdown.
    */
    if (searchType !== SearchType.DICTIONARY) {
      event.preventDefault();

      history.push(
        `/${level}${RoutePath.ARTICLE.replace(
          ':title/:articleId',
          `${suggestion.urlTitle}/${suggestion.articleId}`
        )}`
      );
    }
  }

  // Input search uses event and Autosuggest uses autosuggestEvent.
  // That's the reason we are doing a ternary check to see who's calling it.
  onChange(event, autosuggestEvent) {
    this.setState(
      {
        value: autosuggestEvent ? autosuggestEvent.newValue : event.target.value,
      },
      () => {
        const { value } = this.state;

        this.setState({ isSubmitDisabled: !value });
      }
    );
  }

  static getSuggestionValue(suggestion) {
    return suggestion.urlTitle;
  }

  static renderSuggestion(suggestion) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: suggestion.title,
        }}
      />
    );
  }

  // --- Dropdown

  onSearchTypeChange(event) {
    this.setState({
      searchType: event.target.value,
    });
  }

  onSearchSubmitHandler(event) {
    event.preventDefault();

    const { history, openResultsInNewTab, level } = this.props;
    const { value, searchType } = this.state;
    const path = `/${level}${RoutePath.SEARCH.replace('/:searchType/:searchQuery', '')}`;
    const searchName = `${path}/${searchType}/${value}`;

    if (value.length) {
      if (!openResultsInNewTab) {
        history.push({
          pathname: searchName,
        });
      } else {
        window.open(
          searchName,
          '_blank' // <- This is what makes it open in a new window.
        );
      }

      // Make sure to cancel debounce when search is submitted.
      this.onSuggestionsFetchRequested.cancel();
    }
  }

  onRouteChange() {
    const { match } = this.props;
    const { searchQuery } = match.params;

    this.setState({ value: Utilities.cleanSearchQuery(searchQuery) });
  }

  // --- Render

  render() {
    const { isSubmitDisabled, suggestions, searchType, value } = this.state;

    const {
      onSuggestionsClearRequested,
      onSuggestionsFetchRequested,
      onSuggestionSelected,
      onChange,
      onSearchTypeChange,
      onSearchSubmitHandler,
    } = this;

    return (
      <SearchBox
        {...this.props}
        dropdownOptions={this.dropdownOptions}
        suggestions={suggestions}
        inputProps={{ value, onChange }}
        isSubmitDisabled={isSubmitDisabled}
        onSearchTypeChange={onSearchTypeChange}
        onSearchSubmit={onSearchSubmitHandler}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={SearchBoxContainer.getSuggestionValue}
        renderSuggestion={SearchBoxContainer.renderSuggestion}
        searchOptionValue={searchType}
      />
    );
  }
}

SearchBoxContainer.propTypes = {
  className: PropTypes.string,
  hasAutocomplete: PropTypes.bool,
  hasDropdown: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  size: PropTypes.string,
  searchButtonColor: PropTypes.string,
  openResultsInNewTab: PropTypes.bool,
  level: PropTypes.string.isRequired,
};

SearchBoxContainer.defaultProps = {
  className: '',
  hasAutocomplete: false,
  hasDropdown: false,
  history: null,
  location: null,
  match: null,
  placeholder: 'Pesquisar',
  size: 'sm',
  searchButtonColor: 'quarternary',
  openResultsInNewTab: false,
};

export default withRouter(SearchBoxContainer);
