// Contato
import React from 'react';

const Contact = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Contato</h2>
  </div>
);

export default Contact;
