import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons';
import { individualLoginUrl } from '@britannica/platform-client-lib';
import {
  RoutePath,
  Routes,
  Levels,
  TopicsMiddleHigh,
  TopicColorsMiddle,
  Breakpoints,
  TopicsElementary,
  TopicColorsElementary,
} from '../../constants';
import { IndividualAuth } from '../../pages/IndividualAuth/IndividualAuth.constants';
import { MyBritannica } from '../../pages/MyBritannicaPage/MyBritannica.constants';
import SearchBoxMobileContainer from '../SearchBoxMobile/SearchBoxMobileContainer';
import SearchBoxContainer from '../SearchBox/SearchBoxContainer';
import useLevel from '../../hooks/useLevel.hook';
import useIsIndividualLoginEnabled from '../../hooks/useIsIndividualLoginEnabled.hook';
import HeaderTitle from './HeaderTitle';
import HeaderLogo from './HeaderLogo';
import NavMenuItem from '../NavMenu/NavMenuItem';
import permissionList from '../../utils/permission-utils';
import useWithStore from '../../hooks/useWithStore.hook';
import './Header.scss';

const Header = props => {
  const {
    store: { instAuth },
  } = useWithStore(state => ({
    instAuth: state.instAuth,
  }));

  const userData = instAuth.instUserData;

  const {
    allFavoritesCollection,
    individualAuth,
    isWelcomePage,
    isHelpNavLinkActive,
    isHelpNavLinkVisible,
    isOpen,
    isOpenTopicMenu,
    isSearchVisible,
    isMobileMenuVisible,
    onAuthSignout,
    toggle,
    toggleTopicMenu,
  } = props;

  const { levelPath: level } = useLevel();
  const isIndividualLoginEnabled = useIsIndividualLoginEnabled();

  const levelRoute = `/${level}`;

  const permissions = permissionList(userData);

  const individualLoginRoute = individualLoginUrl('pt-BR');

  return (
    <Navbar className="Header d-flex flex-column" color="white" dark expand="lg" fixed="top">
      <div className="container">
        <a href="#main-ada" className="navbar sr-only sr-only-focusable skip-content">
          Ir para o conteúdo principal
        </a>
      </div>
      <Container>
        <div className="left-navbar-container">
          <HeaderLogo />
          <HeaderTitle />
        </div>

        <div className="right-navbar-container d-lg-none text-right">
          {isSearchVisible && <SearchBoxMobileContainer level={level} />}
          {isMobileMenuVisible && (
            <NavbarToggler onClick={toggleTopicMenu} aria-label="Ativar menu de tópicos">
              <FontAwesomeIcon icon={faGripHorizontal} className="topic-menu-icon" />
            </NavbarToggler>
          )}
          {isWelcomePage && permissions?.length < 1 ? null : (
            <NavbarToggler onClick={toggle} aria-label="Ativar menu de navegação" />
          )}
        </div>

        {isMobileMenuVisible && (
          <Collapse isOpen={isOpenTopicMenu} navbar>
            <MediaQuery maxWidth={Breakpoints.LG - 1}>
              <div className="topics-section-mobile">
                {level === Levels.ELEMENTARY ? (
                  <>
                    {TopicsElementary.map(topic => (
                      <div key={topic.id}>
                        <Link
                          className="topic-section-link-mobile"
                          to={`${levelRoute}${topic.url}`}
                        >
                          <NavMenuItem
                            image={
                              <img
                                aria-hidden="true"
                                src={topic.icon}
                                style={{ height: 'auto' }}
                                alt={topic.title}
                              />
                            }
                            text={topic.title}
                            bgColor={TopicColorsElementary[topic.id]}
                            isWhite={false}
                            isMobile
                          />
                        </Link>
                      </div>
                    ))}
                  </>
                ) : null}
                {level === Levels.MIDDLE || level === Levels.HIGH ? (
                  <>
                    {TopicsMiddleHigh.map(topic => (
                      <div key={topic.id}>
                        <Link
                          className="topic-section-link-mobile"
                          to={`${levelRoute}${topic.url}`}
                        >
                          <NavMenuItem
                            image={
                              <img
                                aria-hidden="true"
                                src={level === Levels.MIDDLE ? topic.iconL2 : topic.iconL3}
                                style={{ height: 'auto' }}
                                alt={topic.title}
                              />
                            }
                            text={topic.title}
                            bgColor={TopicColorsMiddle[topic.id]}
                            isWhite={false}
                            isMobile
                          />
                        </Link>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </MediaQuery>
          </Collapse>
        )}

        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="ml-auto">
            {isSearchVisible && (
              <NavItem className="d-none d-lg-block">
                <SearchBoxContainer
                  hasAutocomplete
                  className="header-search"
                  size="sm"
                  level={level}
                />
              </NavItem>
            )}
            {permissions?.length > 0 && (
              <UncontrolledDropdown nav inNavbar className="navbar-item">
                <DropdownToggle nav caret className="header-profile-dropdown-name">
                  Recursos da Britannica
                </DropdownToggle>
                <DropdownMenu right>
                  {permissions.map(perm => (
                    <a
                      key={perm.name}
                      href={perm.url}
                      target="_blank"
                      rel="noreferrer"
                      role="menuitem"
                    >
                      <button type="button" tabIndex="0" className="dropdown-item">
                        {perm.name}
                      </button>
                    </a>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {isHelpNavLinkVisible && (
              <NavItem className="navbar-item">
                <NavLink
                  tag={RouterNavLink}
                  to={
                    levelRoute +
                    RoutePath.BROWSE_HELP.replace(':theme/:subject?/:topic?', '1/1000/1001')
                  }
                  isActive={isHelpNavLinkActive}
                >
                  Ajuda
                </NavLink>
              </NavItem>
            )}
            {isIndividualLoginEnabled &&
              individualAuth?.responseReturned &&
              individualAuth?.isAuthenticated &&
              (level === Levels.ELEMENTARY || level === Levels.MIDDLE || level === Levels.HIGH) && (
                <UncontrolledDropdown nav inNavbar className="navbar-item">
                  <DropdownToggle nav caret className="header-profile-dropdown-name">
                    {individualAuth.firstName}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {allFavoritesCollection ? (
                      <Link
                        role="menuitem"
                        to={
                          levelRoute +
                          Routes.MY_BRITANNICA.toLink({
                            collectionId: allFavoritesCollection.collectionId,
                          })
                        }
                      >
                        <button type="button" tabIndex="0" className="dropdown-item">
                          {MyBritannica.TITLE}
                        </button>
                      </Link>
                    ) : (
                      <p className="dropdown-item dropdown-item-disabled mb-0">
                        {MyBritannica.TITLE}
                      </p>
                    )}
                    <div className="dropdown-divider" />
                    <DropdownItem onClick={onAuthSignout}>{IndividualAuth.SIGNOUT}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            {isIndividualLoginEnabled &&
              individualAuth?.responseReturned &&
              !individualAuth?.isAuthenticated &&
              (level === Levels.ELEMENTARY || level === Levels.MIDDLE || level === Levels.HIGH) && (
                <NavItem className="navbar-item">
                  <a className="nav-link" href={individualLoginRoute}>
                    <FontAwesomeIcon className="topic-section-icon" icon={faUser} size="1x" />
                    &nbsp; {IndividualAuth.SIGNIN}
                  </a>
                </NavItem>
              )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  allFavoritesCollection: PropTypes.shape(),
  individualAuth: PropTypes.shape({
    error: PropTypes.shape(),
    firstName: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    lastName: PropTypes.string,
    sub: PropTypes.string,
    username: PropTypes.string,
    responseReturned: PropTypes.bool,
  }),
  isWelcomePage: PropTypes.bool,
  isHelpNavLinkActive: PropTypes.func,
  isHelpNavLinkVisible: PropTypes.bool,
  isSearchVisible: PropTypes.bool,
  isMobileMenuVisible: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isOpenTopicMenu: PropTypes.bool.isRequired,
  location: PropTypes.shape().isRequired,
  toggle: PropTypes.func.isRequired,
  toggleTopicMenu: PropTypes.func.isRequired,
  onAuthSignout: PropTypes.func,
};

Header.defaultProps = {
  allFavoritesCollection: null,
  individualAuth: null,
  isWelcomePage: null,
  isHelpNavLinkActive: null,
  isHelpNavLinkVisible: null,
  isSearchVisible: false,
  isMobileMenuVisible: false,
  onAuthSignout: null,
};

export default Header;
