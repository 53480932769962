// Widget: Caixa de pesquisa do Britannica Escola
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClipboardButton from 'react-clipboard.js';
import { InputGroup, InputGroupAddon, Input, Label, FormGroup } from 'reactstrap';

class FunctionalityWdget extends Component {
  constructor(props) {
    super(props);

    const { level } = props;

    this.state = {
      iframeURL: `<iframe src="https://${window.location.host}/widgets/searchbox/${level}.html" frameborder='0' width='320' height='103' scrolling='no'></iframe>`,
      proxyValue: '',
      proxyAddedMessage: 'Proxy adicionado com sucesso',
      showProxyAddedSuccess: false,
    };

    this.addProxy = this.addProxy.bind(this);
    this.setProxyValue = this.setProxyValue.bind(this);
  }

  setProxyValue(e) {
    this.setState({
      proxyValue: e.target.value,
    });
  }

  addProxy() {
    const { proxyValue } = this.state;
    const { level } = this.props;

    // add the proxy feld value to the ifraeURL
    this.setState(
      {
        iframeURL:
          proxyValue !== ''
            ? `<iframe src="https://${
                window.location.host
              }/widgets/searchbox/${level}.html?proxy=${encodeURIComponent(
                proxyValue
              )}" frameborder='0' width='320' height='103' scrolling='no'></iframe>`
            : `<iframe src="https://${window.location.host}/widgets/searchbox/${level}.html" frameborder='0' width='320' height='103' scrolling='no'></iframe>`,
        showProxyAddedSuccess: proxyValue !== '',
      },
      () => {
        this.setState({
          proxyValue: '',
        });
      }
    );
  }

  render() {
    const { iframeURL, proxyValue, proxyAddedMessage, showProxyAddedSuccess } = this.state;
    const { level } = this.props;
    const style = {
      display: 'block',
    };

    return (
      <div className="container">
        <h2 className="toc-level-0 text-color--secondary">
          Widget: Caixa de pesquisa do Britannica Escola
        </h2>
        <iframe
          title="Widget"
          src={`https://${window.location.host}/widgets/searchbox/${level}.html`}
          frameBorder="0"
          width="320"
          height="103"
        />
        <form className="mt-4">
          <FormGroup>
            <Label for="clipboard-value">
              <strong>Copie e cole este código na sua página:</strong>
            </Label>
            <InputGroup>
              <Input id="clipboard-value" value={iframeURL} readOnly />
              <InputGroupAddon addonType="append">
                <ClipboardButton
                  className="btn btn-cust-primary"
                  data-clipboard-target="#clipboard-value"
                  value="#clipboard-value"
                >
                  Copiar
                </ClipboardButton>
              </InputGroupAddon>
            </InputGroup>
            {showProxyAddedSuccess && (
              <span className="valid-feedback" style={style}>
                {proxyAddedMessage}
              </span>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="proxy-add">
              <strong>Usar o prefixo proxy (opcional):</strong>
            </Label>
            <InputGroup>
              <Input id="proxy-add" onChange={this.setProxyValue} value={proxyValue} />
              <InputGroupAddon addonType="append">
                <button onClick={this.addProxy} type="button" className="btn btn-cust-primary">
                  Atualizar
                </button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </form>
      </div>
    );
  }
}

FunctionalityWdget.propTypes = {
  level: PropTypes.string.isRequired,
};

FunctionalityWdget.defaultProps = {};

export default FunctionalityWdget;
