import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { routeToLogin } from '@britannica/platform-client-lib';
import { Redirect } from 'react-router-dom';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import useWithStore from '../../hooks/useWithStore.hook';
import { levelsActions } from '../../store/levels/levels.dux';
import ChildrenProp from '../../prop-types/ChildrenProp';
import { Levels, RoutePath } from '../../constants';

const PERMISSION_RESULT = {
  NO_AUTH: 'no-auth',
  NO_PERMISSION: 'no-permission',
  AUTH_OK: 'auth-ok',
};

const InstAuthRoute = ({ level, isLoading, isAuth, userData, children }) => {
  const { dispatch } = useWithStore(() => ({}), {
    setSelectedLevel: levelsActions.setSelectedLevel,
  });

  useEffect(() => {
    dispatch.setSelectedLevel(level);
  }, [level, dispatch]);

  // function to fix the iOS Safari blank page rendering issue
  // ref: https://github.com/vuejs/vue/issues/5533
  const scrollFix = () => {
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener('popstate', scrollFix);

    return () => window.removeEventListener('popstate', scrollFix);
  }, []);

  const hasPermission = useMemo(() => {
    if (
      !isAuth ||
      !userData ||
      (!userData.permissions?.includes('PORTK6') &&
        !userData.permissions?.includes('PORT6-8') &&
        !userData.permissions?.includes('PORT9-12'))
    ) {
      return PERMISSION_RESULT.NO_AUTH;
    }

    if (level === Levels.ELEMENTARY && !userData.permissions.includes('PORTK6')) {
      return PERMISSION_RESULT.NO_PERMISSION;
    }

    if (level === Levels.MIDDLE && !userData.permissions.includes('PORT6-8')) {
      return PERMISSION_RESULT.NO_PERMISSION;
    }

    if (level === Levels.HIGH && !userData.permissions.includes('PORT9-12')) {
      return PERMISSION_RESULT.NO_PERMISSION;
    }

    return PERMISSION_RESULT.AUTH_OK;
  }, [level, userData]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (hasPermission === PERMISSION_RESULT.NO_AUTH) {
    routeToLogin(false, 'pt-BR');

    return <></>;
  }

  if (hasPermission === PERMISSION_RESULT.NO_PERMISSION) {
    if (userData.permissions.length > 1) {
      return (
        <Redirect
          to={{
            pathname: RoutePath.HOME,
            state: { from: window.location.pathname, userData },
          }}
        />
      );
    }

    let redirectPath = `/${Levels.ELEMENTARY}`;

    if (userData.permissions.includes('PORT6-8')) {
      redirectPath = `/${Levels.MIDDLE}`;
    } else if (userData.permissions.includes('PORT9-12')) {
      redirectPath = `/${Levels.HIGH}`;
    }

    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: window.location.pathname, userData },
        }}
      />
    );
  }

  return (
    <>
      {level && (
        <Helmet>
          <html data-level={level} lang="pt-br" />
        </Helmet>
      )}
      {children}
    </>
  );
};

InstAuthRoute.propTypes = {
  level: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    accountId: PropTypes.number,
    accountName: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    preferences: PropTypes.shape(),
    realism: PropTypes.string,
    region: PropTypes.string,
    type: PropTypes.string,
  }),
  children: ChildrenProp,
};

InstAuthRoute.defaultProps = {
  level: '',
  userData: null,
};

export default InstAuthRoute;
