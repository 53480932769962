import { createContext } from 'react';

// Import reducers into store.
import { individualAuthReducer } from '../pages/IndividualAuth/IndividualAuth.dux';
import { instAuthReducer } from './InstAuth/InstAuth.dux';
import { myBritannicaReducer } from '../pages/MyBritannicaPage/MyBritannica.dux';
import { preferencesReducer } from './preferences/preferences.dux';
import { levelsReducer } from './levels/levels.dux';

// Combine all reducers
export const combineReducers = (state = {}, action = {}) => {
  /**
   * We might need to do a shallow scan on the "state" object by doing a reference equality check on its properties
   * to figure out if we just want to return the current state or generate a new one.
   * This is only possible if the reducers we're building are updating their states immutability.
   *
   * Doing this will help reduce the amount of unnecessary re-renders in the "StoreProvider".
   */

  return {
    instAuth: instAuthReducer(state.instAuth, action),
    individualAuth: individualAuthReducer(state.individualAuth, action),
    myBritannica: myBritannicaReducer(state.myBritannica, action),
    preferences: preferencesReducer(state.preferences, action),
    levels: levelsReducer(state.levels, action),
  };
};

// Create global store context and set initialState.
export const initialState = combineReducers();
export const store = createContext([initialState, () => {}]);
