import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { individualLogoutUrl } from '@britannica/platform-client-lib';

import { Levels, RoutePath } from '../../constants';
import { individualAuthActions } from '../../pages/IndividualAuth/IndividualAuth.dux';
import { myBritannicaActions } from '../../pages/MyBritannicaPage/MyBritannica.dux';
import useWithStore from '../../hocs/useWithStore.hoc';
import Header from './Header';

class HeaderContainer extends Component {
  static isHelpNavLinkActiveHandler(match, location) {
    return location.pathname.split('/')[2] === RoutePath.HELP.split('/')[1];
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isOpenTopicMenu: false,
    };

    this.onSignoutHandler = this.onSignoutHandler.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleTopicMenu = this.toggleTopicMenu.bind(this);
  }

  // --- Lifecycle methods

  static getDerivedStateFromProps(props, state) {
    // Close the menu if we're transitioning to a new route

    if (props.location.pathname !== state.pathname) {
      return {
        isOpen: false,
        pathname: props.location.pathname,
      };
    }

    return null;
  }

  // --- Custom methods

  onSignoutHandler() {
    const { individualAuthSignoutHandler, setCollection, setCollections } = this.props;

    setCollection(null);
    setCollections(null);
    individualAuthSignoutHandler();

    const logoutUrl = individualLogoutUrl('pt-BR');

    window.location.replace(logoutUrl);
  }

  toggle() {
    if (this.state.isOpenTopicMenu === true) {
      this.setState(() => ({ isOpenTopicMenu: false }));
      setTimeout(() => {
        this.setState(previousState => ({ isOpen: !previousState.isOpen }));
      }, 300);
    } else {
      this.setState(previousState => ({ isOpen: !previousState.isOpen }));
    }
  }

  toggleTopicMenu() {
    if (this.state.isOpen === true) {
      this.setState(() => ({ isOpen: false }));
      setTimeout(() => {
        this.setState(previousState => ({ isOpenTopicMenu: !previousState.isOpenTopicMenu }));
      }, 300);
    } else {
      this.setState(previousState => ({ isOpenTopicMenu: !previousState.isOpenTopicMenu }));
    }
  }

  // --- Render

  render() {
    const { allFavoritesCollection, match, individualAuth, location } = this.props;
    const { isOpen, isOpenTopicMenu } = this.state;
    const { onSignoutHandler } = this;
    const { isHelpNavLinkActiveHandler } = HeaderContainer;

    return (
      <Header
        allFavoritesCollection={allFavoritesCollection}
        isOpen={isOpen}
        isOpenTopicMenu={isOpenTopicMenu}
        toggle={this.toggle}
        toggleTopicMenu={this.toggleTopicMenu}
        individualAuth={individualAuth}
        isWelcomePage={match.path === RoutePath.HOME}
        isHelpNavLinkActive={isHelpNavLinkActiveHandler}
        isHelpNavLinkVisible={match.path !== RoutePath.HOME}
        isSearchVisible={
          match.path !== RoutePath.HOME &&
          !match.path?.endsWith(`/${Levels.ELEMENTARY}/`) &&
          !match.path?.endsWith(`/${Levels.MIDDLE}/`) &&
          !match.path?.endsWith(`/${Levels.HIGH}/`) &&
          !match.path?.endsWith(RoutePath.SEARCH) &&
          !match.path?.endsWith(RoutePath.MEDIA_OVERLAY)
        }
        isMobileMenuVisible={
          match.path?.endsWith(`/${Levels.ELEMENTARY}/`) ||
          match.path?.endsWith(`/${Levels.MIDDLE}/`) ||
          match.path?.endsWith(`/${Levels.HIGH}/`)
        }
        location={location}
        onAuthSignout={onSignoutHandler}
      />
    );
  }
}

HeaderContainer.propTypes = {
  allFavoritesCollection: PropTypes.shape(),
  individualAuth: PropTypes.shape().isRequired,
  individualAuthSignoutHandler: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  setCollection: PropTypes.func.isRequired,
  setCollections: PropTypes.func.isRequired,
};

HeaderContainer.defaultProps = {
  allFavoritesCollection: null,
  match: null,
};

const mapStateToProps = state => ({
  individualAuth: state.individualAuth,
  allFavoritesCollection:
    !state.myBritannica.collections || state.myBritannica.collections instanceof Error
      ? null
      : state.myBritannica.collections.find(collection => collection.isAllFavoritesCollection),
});

const mapActionsToProps = {
  individualAuthSignoutHandler: individualAuthActions.individualAuthSignout,
  setCollection: myBritannicaActions.setCollection,
  setCollections: myBritannicaActions.setCollections,
};

export default useWithStore(mapStateToProps, mapActionsToProps)(withRouter(HeaderContainer));
