import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import '../../App.scss';
import Footer from '../../components/Footer/Footer';
import HeaderContainer from '../../components/Header/HeaderContainer';
import useLevel from '../../hooks/useLevel.hook';
import { LevelTitles } from '../../constants';

const DefaultTemplate = ({ children }) => {
  const { levelPath } = useLevel();

  const mainTitle = LevelTitles[levelPath]
    ? `Britannica Escola - ${LevelTitles[levelPath]}`
    : 'Britannica Escola';

  return (
    <>
      <Helmet
        defaultTitle={mainTitle}
        titleTemplate="%s | Britannica Escola"
        meta={[
          {
            name: 'description',
            content:
              'Britannica® Escola é uma plataforma de aprendizagem online para os alunos do Ensino Fundamental I.',
          },
          {
            name: 'keywords',
            content:
              'Artigos de la Enciclopédia Escolar, dicionário, imagens, Busca A-Z, Biografias, Reino animal, Videoteca, Atlas do Mundo, Descubra o Brasil, Notícias',
          },
        ]}
      />
      <HeaderContainer />
      {children}
      <Footer />
    </>
  );
};

DefaultTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultTemplate;
