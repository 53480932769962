import React from 'react';
import PropTypes from 'prop-types';
import ErrorPrompt from '../ErrorPrompt/ErrorPrompt';
import 'animate.css';
import './Loading.scss';

const renderChildren = children => (typeof children === 'function' ? children() : children);

const Loading = props => {
  const { children, displayLoader, errorMessage, hasError, isLoading, loader, onReload } = props;

  return (
    <ErrorPrompt customErrorMessage={errorMessage} displayError={hasError} onReload={onReload}>
      {isLoading || hasError ? displayLoader && loader : renderChildren(children)}
    </ErrorPrompt>
  );
};

Loading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  displayLoader: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  loader: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  onReload: PropTypes.func,
};

Loading.defaultProps = {
  children: null,
  displayLoader: true, // <-- "set to true to make it backwards compatible with components that are already using "Loading" directly"
  errorMessage: 'Não foi possível carregar os dados', // <-- "Unable to load data"
  hasError: false,
  isLoading: false,
  loader: (
    <>
      <div className="loading-elementary animated">
        <div className="bubble-1" />
        <div className="bubble-2" />
        <div className="bubble-3" />
        <div className="bubble-4" />
      </div>
      <div className="loading-middle">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <div className="loading-high">
        <div className="loader">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </>
  ),
  onReload: null,
};

export default Loading;
