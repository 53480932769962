// Capacitação e recursos
import React from 'react';

const ContactTraining = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Capacitação e recursos</h2>
    <p>
      Professor: Consulte nossa página{' '}
      <a href="//britannica.com.br/Training-Pt.html" rel="noopener noreferrer" target="_blank">
        Capacitação e recursos
      </a>{' '}
      para registrar-se nas sessões de treinamento e aprender mais sobre como aproveitar o
      Britannica Escola em suas aulas.
    </p>
  </div>
);

export default ContactTraining;
