/**
 * Return an array containing the list of product names and URLs
 * in which an specific user account has permission to access
 *
 * @param {object<key, value>} userData
 * @returns {object<name, url>}
 */
const permissionList = userData => {
  if (!userData?.permissions) {
    return [];
  }

  return userData.permissions
    .reduce((acc, cur) => {
      switch (cur) {
        case 'BOL':
          acc.push({ name: 'Academic', url: process.env.REACT_APP_URL_ACADEMIC });
          break;
        case 'SE':
          acc.push({ name: 'Britannica School', url: process.env.REACT_APP_URL_SCHOOL });
          break;
        case 'PACKS-SS':
        case 'PACKS-SCI':
          if (!acc.find(e => e.name === 'LaunchPacks')) {
            acc.push({ name: 'LaunchPacks', url: process.env.REACT_APP_URL_PACKS });
          }

          break;
        case 'BIQ':
          acc.push({ name: 'ImageQuest', url: process.env.REACT_APP_URL_IQ });
          break;
        case 'BIQJR':
          acc.push({ name: 'ImageQuest Jr', url: process.env.REACT_APP_URL_IQJR });
          break;
        case 'MOD':
          acc.push({ name: 'Enciclopedia Moderna', url: process.env.REACT_APP_URL_MODERNA });
          break;
        case 'JUVE':
          acc.push({ name: 'Escolar Online', url: process.env.REACT_APP_URL_ESCOLAR });
          break;
        case 'EU-SENIOR':
          acc.push({
            name: 'Encyclopaedia Universalis',
            url: `${process.env.REACT_APP_URL_UNIVERSALIS}&accountId=${userData.accountId}`,
          });
          break;
        case 'EU-JUNIOR':
          acc.push({
            name: 'Encyclopaedia Universalis Junior',
            url: `${process.env.REACT_APP_URL_UNIVERSALISJR}&accountId=${userData.accountId}`,
          });
          break;
        case 'BOLJ':
          acc.push({ name: 'Britannica Online Japan', url: process.env.REACT_APP_URL_JAPAN });
          break;
        case 'AOA':
          acc.push({ name: 'Annals of America', url: process.env.REACT_APP_URL_ANNALS });
          break;
        case 'WDI':
          acc.push({ name: 'World Data Analyst', url: process.env.REACT_APP_URL_WORLDDATA });
          break;
        case 'MWU':
          acc.push({ name: 'Merriam-Webster Unabridged', url: process.env.REACT_APP_URL_MWU });
          break;
        case 'WS':
          acc.push({ name: 'Original Sources', url: process.env.REACT_APP_URL_SOURCES });
          break;
        default:
      }

      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));
};

export default permissionList;
