import React from 'react';
import PropTypes from 'prop-types';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroupItem, ListGroup, Collapse } from 'reactstrap';
import ListGroupCollapse from './ListGroupCollapse';
import { Levels } from '../../constants';

const AccordionNavigation = props => {
  const {
    isRootNodeOpen,
    toggleRootNode,
    hiearchyGroups,
    rootNode,
    groupOpenStateHash,
    toggleChildNode,
    theme,
    subject,
    topic,
    browseType,
    mobileDropdownLabel,
    level,
  } = props;
  const mainToggle = isRootNodeOpen ? 'root d-md-none open' : 'root d-md-none closed';
  const showTopics = hiearchyGroups.length;

  return (
    <>
      {!!showTopics && (
        <nav className="Accordion-Navigation">
          <ListGroup id="list-group--root">
            {level === Levels.ELEMENTARY && (
              <ListGroupItem onClick={toggleRootNode} className={mainToggle}>
                {rootNode ? mobileDropdownLabel : 'Loading...'}
                <FontAwesomeIcon className="open-menu" icon={faSortUp} size="1x" />
                <FontAwesomeIcon className="closed-menu" icon={faSortDown} size="1x" />
              </ListGroupItem>
            )}
            <Collapse tag="li" isOpen={isRootNodeOpen}>
              {hiearchyGroups.map(item => (
                <ListGroupCollapse
                  key={item.browseId}
                  item={item}
                  rootId={rootNode.browseId}
                  groupOpenStateHash={groupOpenStateHash}
                  toggleChildNode={toggleChildNode}
                  theme={theme}
                  subject={subject}
                  topic={topic}
                  browseType={browseType}
                  level={level}
                />
              ))}
            </Collapse>
          </ListGroup>
        </nav>
      )}
    </>
  );
};

AccordionNavigation.propTypes = {
  isRootNodeOpen: PropTypes.bool,
  toggleRootNode: PropTypes.func.isRequired,
  hiearchyGroups: PropTypes.arrayOf(PropTypes.shape()),
  rootNode: PropTypes.shape(),
  groupOpenStateHash: PropTypes.shape(),
  toggleChildNode: PropTypes.func.isRequired,
  theme: PropTypes.string,
  subject: PropTypes.string,
  topic: PropTypes.string,
  browseType: PropTypes.string.isRequired,
  mobileDropdownLabel: PropTypes.string,
  level: PropTypes.string,
};

AccordionNavigation.defaultProps = {
  isRootNodeOpen: true,
  hiearchyGroups: null,
  rootNode: null,
  groupOpenStateHash: null,
  theme: null,
  subject: null,
  topic: null,
  mobileDropdownLabel: 'Temas',
};

export default AccordionNavigation;
