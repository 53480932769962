// Requisitos técnicos
import React from 'react';

const RequirementsTechnical = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Requisitos técnicos</h2>
    <h3 className="toc-level-3">Navegadores e plugins</h3>
    <p>
      Como o acesso ao Britannica Escola é feito pela internet, o portal está sujeito aos mesmos
      problemas de desempenho que afetam outros websites. A Britannica faz todo o possível para
      manter o acesso ao Britannica Escola rápido e eficiente. As escolas também podem tomar algumas
      medidas para melhorar o desempenho do Britannica Escola (e de outros websites). A Britannica
      recomenda o uso dos seguintes navegadores, atualizados até pelo menos a antepenúltima versão:{' '}
      <strong>Google Chrome</strong>, <strong>Mozilla Firefox</strong>,{' '}
      <strong>Microsoft Edge</strong> ou <strong>Apple Safari</strong>.
    </p>
    <p>
      A seção <strong>É hora de aprender com a Britannica</strong> requer que o plugin do Adobe
      Flash Player esteja habilitado. Versões recentes do navegador Google Chrome desabilitam o
      Flash automaticamente. Para permitir o uso do Flash no Google Chrome, acesse as configurações
      avançadas do navegador.
    </p>
  </div>
);

export default RequirementsTechnical;
