import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyHomePage = lazy(() => import('./HomePageContainer'));

const HomePage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyHomePage {...props} />
  </Suspense>
);

export default HomePage;
