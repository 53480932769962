import { WebStats } from '../constants';

class AnalyticsApi {
  static writeStat(instAuth) {
    try {
      if (window.writeStat && typeof window.writeStat === 'function') {
        window.writeStat(null, {
          domain: WebStats.HOST,
          hostnameOverride: WebStats.HOSTNAME_OVERRIDE,
          accountId: instAuth.instUserData.accountId,
        });
      }
    } catch (error) {
      console.error('EB Webstats problem.');
      console.error(error);
    }
  }
}

export default AnalyticsApi;
