export const MyBritannica = {
  // ADD_ITEM CUSTOMTOOLTIP
  ADD_ITEM_CUSTOMTOOLTIP_DESCRIPTION: 'Salvar este recurso nas suas coleções',
  ADD_ITEM_CUSTOMTOOLTIP_PRIMARY_BUTTON: 'Salvar',
  ADD_ITEM_CUSTOMTOOLTIP_SECONDARY_BUTTON: 'Cancelar',

  // ADD_TO_FAVORITES FAVORITED state
  ADD_TO_FAVORITES_FAVORITED_STATE_TOOLTIP: 'Remover dos favoritos',
  ADD_TO_FAVORITES_FAVORITED_STATE_TITLE: 'Remover dos favoritos e das coleções',
  ADD_TO_FAVORITES_FAVORITED_STATE_DESCRIPTION:
    'Ao remover conteúdo da lista "Todos os favoritos", você também irá removê-lo das coleções em destaque. Deseja continuar?',
  ADD_TO_FAVORITES_FAVORITED_STATE_PRIMARY_BUTTON: 'Remover',
  ADD_TO_FAVORITES_FAVORITED_STATE_SECONDARY_BUTTON: 'Ir para a página Minha Britannica',

  // ADD_TO_FAVORITES UNFAVORITED state
  ADD_TO_FAVORITES_UNFAVORITED_STATE_TOOLTIP: 'Salvar como favorito',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_TITLE: 'Salvar como favorito',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_DESCRIPTION_ACTION_ONE: 'Salvar nas suas coleções',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_DESCRIPTION_ACTION_TWO: 'Salvar em uma nova coleção',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_NEW_COLLECTION_PLACEHOLDER: 'Nome da nova coleção',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_PRIMARY_BUTTON: 'Salvar',
  ADD_TO_FAVORITES_UNFAVORITED_STATE_SECONDARY_BUTTON: 'Cancelar',

  // COLLECTION_ITEMS_PANEL header
  COLLECTION_ITEMS_PANEL_TITLE_EDIT_TOOLTIP: 'Editar',
  COLLECTION_ITEMS_PANEL_ADD_DESCRIPTION: 'Acrescentar descrição',
  COLLECTION_ITEMS_PANEL_DELETE_COLLECTION_BUTTON_LABEL: 'Deletar',
  COLLECTION_ITEMS_PANEL_SHARE_COLLECTION_BUTTON_LABEL: 'Compartilhar',

  // COLLECTION_ITEMS_PANEL DELETE_COLLECTION_MODAL
  COLLECTION_ITEMS_PANEL_DELETE_COLLECTION_MODAL_DESCRIPTION:
    'Você irá deletar a coleção "{x}". Não é possível desfazer essa ação. Deseja continuar?',
  COLLECTION_ITEMS_PANEL_DELETE_COLLECTION_MODAL_PRIMARY_BUTTON: 'Deletar',
  COLLECTION_ITEMS_PANEL_DELETE_COLLECTION_MODAL_SECONDARY_BUTTON: 'Cancelar',
  COLLECTION_ITEMS_PANEL_DELETE_COLLECTION_MODAL_TITLE: 'Deletar coleção',

  // COLLECTION_ITEMS_PANEL FILTER_COLLECTION
  COLLECTION_ITEMS_PANEL_FILTER_BY: 'Tipo de conteúdo:',

  // COLLECTION_ITEMS_PANEL SHARE_COLLECTION_MODAL
  COLLECTION_ITEMS_PANEL_SHARE_COLLECTION_MODAL_DESCRIPTION:
    'Copie o link da coleção "{x}" para colá-lo em mensagens e trabalhos, ou salve-o para acessá-lo mais tarde.',
  COLLECTION_ITEMS_PANEL_SHARE_COLLECTION_MODAL_PRIMARY_BUTTON: 'Copiar link',
  COLLECTION_ITEMS_PANEL_SHARE_COLLECTION_MODAL_PRIMARY_BUTTON_TOOLTIP_LABEL: 'Copiado!',
  COLLECTION_ITEMS_PANEL_SHARE_COLLECTION_MODAL_TITLE: 'Compartilhar coleção',

  // COLLECTIONS_PANEL
  COLLECTIONS_PANEL_CANCEL_BUTTON_LABEL: 'Cancelar',
  COLLECTIONS_PANEL_CREATE_BUTTON_LABEL: 'Criar',
  COLLECTIONS_PANEL_COUNT_OF_RESOURCES: 'Recursos',
  COLLECTIONS_PANEL_COUNT_OF_RESOURCE: 'Recurso',
  COLLECTIONS_PANEL_NEW_COLLECTION_BUTTON_LABEL: 'Criar nova coleção',
  COLLECTIONS_PANEL_NEW_COLLECTION_FIELD_PLACEHOLDER: 'Nome da nova coleção',

  // ERROR_MESSAGE
  ERROR_MESSAGE_CAN_NOT_FAVORITE:
    'Ops! Alguma coisa deu errado. Não conseguimos salvar esse recurso como favorito. Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_UNFAVORITE:
    'Ops! Alguma coisa deu errado. Não conseguimos remover esse recurso dos favoritos e das coleções. Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_ADD_ITEM:
    'Ops! Alguma coisa deu errado. Não conseguimos salvar esse recurso na(s) coleção(ões) que você selecionou. Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_REMOVE_ITEM:
    'Ops! Alguma coisa deu errado. Não conseguimos salvar esse recurso como favorito. Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_CREATE_NEW_COLLECTION:
    'Ops! Alguma coisa deu errado. Não conseguimos criar a coleção "{x}". Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_DELETE_COLLECTION:
    'Ops! Alguma coisa deu errado. Não conseguimos deletar a coleção "{x}". Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_SAVE_CHANGES_TO_COLLECTION:
    'Ops! Alguma coisa deu errado. Não conseguimos salvar as mudanças que você fez na sua coleção. Tente de novo.',

  ERROR_MESSAGE_CAN_NOT_SAVE_NOTE:
    'Ops! Alguma coisa deu errado. Não conseguimos salvar suas anotações. Tente de novo.',

  // NOTE CUSTOMTOOLTIP
  NOTE_CUSTOMTOOLTIP_BUTTON_TOOLTIP: 'Anotações',
  NOTE_CUSTOMTOOLTIP_PLACEHOLDER_TEXT: 'Faça suas anotações aqui',
  NOTE_CUSTOMTOOLTIP_PRIMARY_BUTTON: 'Salvar',
  NOTE_CUSTOMTOOLTIP_SECONDARY_BUTTON: 'Cancelar',
  NOTE_CUSTOMTOOLTIP_TITLE: 'Anotações',

  // REMOVE_ITEM CUSTOMTOOLTIP
  REMOVE_ITEM_CUSTOMTOOLTIP_COLLECTION_DESCRIPTION:
    'Ao remover conteúdo de uma coleção, você não irá removê-lo da lista "Todos os favoritos". Deseja continuar?',
  REMOVE_ITEM_CUSTOMTOOLTIP_COLLECTION_TITLE: 'Remover da coleção',
  REMOVE_ITEM_CUSTOMTOOLTIP_FAVORITES_DESCRIPTION:
    'Ao remover conteúdo da lista "Todos os favoritos", você também irá removê-lo das coleções. Deseja continuar?',
  REMOVE_ITEM_CUSTOMTOOLTIP_FAVORITES_TITLE: 'Remover dos favoritos',
  REMOVE_ITEM_CUSTOMTOOLTIP_PRIMARY_BUTTON: 'Remover',
  REMOVE_ITEM_CUSTOMTOOLTIP_SECONDARY_BUTTON: 'Cancelar',

  // REORDER_ITEM GRIP
  REORDER_ITEM_GRIP_BUTTON_TOOLTIP: 'Reorganizar',

  // SHARED_COLLECTION page
  SHARED_COLLECTION_PAGE_TITLE: 'Coleção compartilhada',
  SHARED_COLLECTION_ERROR_MESSAGE: 'Esta coleção foi deletada pela pessoa que a compartilhou.',

  // SIGN_IN
  SIGN_IN_TITLE: 'Fazer login',
  SIGN_IN_DESCRIPTION: 'Faça login para salvar seus recursos favoritos.',

  // WHERE_IS_MY_STUFF
  WHERE_IS_MY_STUFF_LINK_TEXT: 'Cadê minha conta antiga?',
  WHERE_IS_MY_STUFF_DESCRIPTION:
    'Em fevereiro de 2019, o Britannica Escola foi totalmente remodelado, e esperamos que você esteja gostando do novo design e das ferramentas atualizadas. Infelizmente, não foi possível manter as contas criadas na versão antiga do site. Pedimos desculpas por qualquer inconveniente.',

  ALL_FAVORITES_HEADER_TEXT: 'Todos os favoritos',
  ADD_TO_COLLECTION: 'Salvar em uma coleção',
  ARTICLE: 'Artigo',
  AUDIO: 'Áudio',
  IMAGE: 'Imagem',
  FAVORITES: 'Favoritos',
  FAVORITE: 'Favorito',
  MODAL_CLOSE_BUTTON: 'Fechar',
  NO_CONTENT: 'NENHUM CONTEÚDO',
  REMOVE: 'Remover',
  TITLE: 'Minha Britannica',
  VIDEO: 'Vídeo',
};

export const MyBritannicaType = {
  ALL: 'Todos',
  ARTICLE: 'Artigos',
  AUDIO: 'Áudios',
  IMAGE: 'Imagens',
  VIDEO: 'Vídeos',
};
