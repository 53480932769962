import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseGamePage = lazy(() => import('./BrowseGamePageContainer'));

const BrowseGamePage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseGamePage {...props} />
  </Suspense>
);

export default BrowseGamePage;
