// Initial state
const initialState = {
  isAuthenticated: false,
  instUserData: null,
  error: null,
  responseReturned: false,
};

// Actions
const INST_AUTH_SUCCESS = 'INST_AUTH_SUCCESS';
const INST_AUTH_FAIL = 'INST_AUTH_FAIL';

// Action creators
export const instAuthActions = {
  instAuthSuccess: auth => ({
    type: INST_AUTH_SUCCESS,
    payload: auth,
  }),

  instAuthFail: error => ({
    type: INST_AUTH_FAIL,
    payload: error,
  }),
};

// Reducer

export const instAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case INST_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        instUserData: action.payload,
        error: null,
        responseReturned: true,
      };
    case INST_AUTH_FAIL:
      return {
        ...initialState,
        error: {
          status: action.payload.status,
          statusText: action.payload.statusText || action.payload.message,
        },
        responseReturned: true,
      };
    default:
      return state;
  }
};
