import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseAtlasPage = lazy(() => import('./BrowseAtlasPageContainer'));

const BrowseAtlasPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseAtlasPage {...props} />
  </Suspense>
);

export default BrowseAtlasPage;
