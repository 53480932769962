import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazySearchResultsPage = lazy(() => import('./SearchResultsPageContainer'));

const SearchResultsPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazySearchResultsPage {...props} />
  </Suspense>
);

export default SearchResultsPage;
