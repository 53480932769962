import FetchManager from './FetchManager';
import { Endpoint } from '../constants';
import { store } from '../store/store';

class AuthApi extends FetchManager {
  constructor() {
    super();
    this.dispatch = store.dispatch;
  }

  isAuth() {
    return this.fetch(`${Endpoint.BACKEND_API}/isAuth`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
  }
}

export default new AuthApi();
