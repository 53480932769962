// Acessibilidade ao portal
import React from 'react';

const RequirementsAccessibility = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Acessibilidade ao portal</h2>
    <p>
      Para cumprir nosso compromisso com a acessibilidade universal, adotamos como principais
      diretrizes a Seção 508 dos padrões de acessibilidade à web determinados pelo U.S. General
      Services Administration (Administração de Serviços Gerais dos Estados Unidos) e as Diretrizes
      de Acessibilidade ao Conteúdo da Web 2.0 (WCAG - Web Content Accessibility Guidelines 2.0),
      recomendadas pelo World Wide Web Consortium (W3C). Testamos continuamente nossos sites online
      para garantir o cumprimento dessas normas. No entanto, a acessibilidade aos portais da
      Britannica depende da tecnologia de terceiros no que diz respeito a sistema operacional,
      plataformas de hardware e tecnologias de apoio, sendo que tais fatores podem afetar o nível de
      acessibilidade a nossos portais. Para garantir a acessibilidade aos recursos em aula,
      consultamos com regularidade membros da comunidade de pessoas com deficiência. Vamos seguir
      melhorando a acessibilidade a nossos recursos online à medida que estejam disponíveis
      funcionalidades mais avançadas, a fim de assegurar sua utilização constante por parte de todos
      os usários, inclusive as pessoas com deficiência.
    </p>
    <p>
      Estamos comprometidos em abordar as questões que impedem o uso de nosso software por pessoas
      com deficiência. Agradecemos seus comentários e sugestões. Se você tem observações ou
      perguntas sobre a acessibilidade de nossos produtos, por favor, entre em contato através do
      e-mail <a href="mailTo:brazil@eb.com">brazil@eb.com</a>.
    </p>
    <p>
      Não hesite em se manifestar caso encontre qualquer problema ao acessar os websites da
      Britannica.
    </p>
  </div>
);

export default RequirementsAccessibility;
