import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyMyBritannicaPage = lazy(() => import('./MyBritannicaPage'));

const MyBritannicaPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyMyBritannicaPage {...props} />
  </Suspense>
);

export default MyBritannicaPage;
