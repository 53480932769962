// Opções de pesquisa
import React from 'react';

const FunctionalitySearchOptionsElementary = () => (
  <div className="container">
    <h2 className="toc-level-1 text-color--secondary">Opções de pesquisa</h2>
    <p>
      A grande variedade de opções de pesquisa oferecida pelo Britannica Escola facilita o
      aprendizado sobre pessoas e assuntos fascinantes, tanto do presente quanto do passado.
      Selecione uma categoria (Busca A-Z, Biografias, Pesquisa por tema, Reino animal ou Videoteca)
      e explore os tópicos que mais lhe interessam.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Pesquisa por tema</h3>
    <p>
      A pesquisa por tema no Britannica Escola aprofunda o aprendizado sobre diversos assuntos
      interessantes, como artes, natureza e tecnologia. Navegue pelo conteúdo escolhendo um tema e,
      quando possível, um subtema. Clique em qualquer resultado para ler o artigo completo e
      visualizar os vídeos e imagens relacionados.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Reino animal</h3>
    <p>
      Pesquise animais por grupo ou habitat. Clique em um grupo para ler sobre os animais que
      pertencem a ele, ou selecione um habitat para aprender mais sobre os animais que vivem em cada
      ambiente.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Biografias</h3>
    <p>
      Descubra fatos sobre personalidades interessantes do passado e do presente. Clique na primeira
      letra do sobrenome da pessoa, depois clique no artigo para ler a biografia e acessar os
      recursos multimídia.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Atlas do mundo</h3>
    <p>
      Digite o nome de qualquer país para encontrá-lo no mapa ou para acessar o artigo sobre ele.
      Você também pode fazer buscas no atlas clicando em qualquer país ou região.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Descubra o Brasil</h3>
    <p>
      Aprenda mais sobre o Brasil navegando pelo mapa do país e lendo os artigos sobre os estados.
    </p>
  </div>
);

const FunctionalitySearchOptionsMiddleHigh = () => (
  <div className="container">
    <h2 className="toc-level-1 text-color--secondary">Opções de pesquisa</h2>
    <p>
      A grande variedade de opções de pesquisa oferecida pelo Britannica Escola facilita o
      aprendizado sobre pessoas e assuntos fascinantes, tanto do presente quanto do passado.
      Selecione uma categoria (Busca A-Z, Biografias ou Videoteca) e explore os tópicos que mais lhe
      interessam.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Pesquisa de imagens</h3>
    <p>
      Escolha entre uma variedade de imagens para ter uma experiência de aprendizado visualmente
      estimulante. Clique em qualquer imagem para aprofundar-se e aprimorar seu conhecimento.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Biografias selecionadas</h3>
    <p>
      Conheça as figuras históricas mais influentes do passado e presente por meio de artigos e
      imagens relacionadas. Cada biografia oferece uma visão detalhada sobre os impactos e
      conquistas desses notáveis indivíduos. Clique em uma das biografias para iniciar essa jornada
      de conhecimento repleta de histórias interessantes.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Video da semana</h3>
    <p>
      Descubra semanalmente novos temas em nosso “Vídeo da semana”. Os conteúdos são fascinantes,
      trazendo sempre uma visão interessante sobre ciência, história e cultura. Mantenha-se
      informado e adquira novos conhecimentos a cada semana.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Brasil em destaque</h3>
    <p>
      Explore a diversidade do Brasil por meio de incríveis imagens na nossa seção “Brasil em
      destaque”. Desde paisagens urbanas até maravilhas naturais, cada foto está associada a um
      artigo que revela as histórias e a cultura do país. Clique em qualquer imagem para embarcar
      numa instigante jornada pelas riquezas do presente e do passado do Brasil.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Atlas do mundo</h3>
    <p>
      Digite o nome de qualquer país para encontrá-lo no mapa ou para acessar o artigo sobre ele.
      Você também pode fazer buscas no atlas clicando em qualquer país ou região.
    </p>

    <h3 className="toc-level-3 text-color--secondary">Descubra o Brasil</h3>
    <p>
      Aprenda mais sobre o Brasil navegando pelo mapa do país e lendo os artigos sobre os estados.
    </p>
  </div>
);

export { FunctionalitySearchOptionsElementary, FunctionalitySearchOptionsMiddleHigh };
