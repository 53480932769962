import ResultsArticleItem from '../../components/ResultsList/ResultsArticleItem';
import ResultsVideoItem from '../../components/ResultsList/ResultsVideoItem';
import ResultsImageItem from '../../components/ResultsList/ResultsImageItem';
import { BrowseType } from '../../constants';

// `topicPath` doesn't need to be translated because it is a path to backend apis

export const AlphaTypes = {
  [BrowseType.AZ]: {
    dropdownTitle: 'Artigos',
    helmetDescription:
      'Nesta página, você pode pesquisar os artigos do Britannica Escola em ordem alfabética.',
    helmetTitle: 'Pesquisa alfabética',
    idType: 'articleId',
    pageTitle: 'Pesquisa alfabética',
    ResultsListItemComponent: ResultsArticleItem,
    topicPath: '/browse/alpha/article/:level',
  },
  [BrowseType.BIOGRAPHIES]: {
    dropdownTitle: 'Artigos',
    helmetDescription:
      'Nesta página, você pode pesquisar as biografias de pessoas famosas em ordem alfabética.',
    helmetTitle: 'Biografias',
    idType: 'articleId',
    pageTitle: 'Biografias',
    ResultsListItemComponent: ResultsArticleItem,
    topicPath: '/browse/alpha/biography/:level',
  },
  [BrowseType.VIDEOS]: {
    dropdownTitle: 'Vídeos',
    helmetDescription:
      'Nesta página, você pode pesquisar os vídeos do Britannica Escola em ordem alfabética.',
    helmetTitle: 'Videoteca',
    idType: 'assemblyId',
    pageTitle: 'Videoteca',
    ResultsListItemComponent: ResultsVideoItem,
    topicPath: '/browse/alpha/video/:level',
  },
  [BrowseType.IMAGES]: {
    dropdownTitle: 'Imagens',
    helmetDescription:
      'Nesta página, você pode pesquisar as imagens do Britannica Escola em ordem alfabética.',
    helmetTitle: 'Imagens',
    idType: 'assemblyId',
    pageTitle: 'Imagens',
    ResultsListItemComponent: ResultsImageItem,
    topicPath: '/browse/alpha/image/:level',
  },
  [BrowseType.DYK]: {
    dropdownTitle: 'Você conhece?',
    helmetDescription:
      'Nesta página, você pode pesquisar as vídeos da seção "Você conhece?" do Britannica Escola em ordem alfabética.',
    helmetTitle: 'Você conhece?',
    idType: 'assemblyId',
    pageTitle: 'Você conhece?',
    ResultsListItemComponent: ResultsVideoItem,
    topicPath: '/browse/alpha/dyk/:level',
  },
};

export const PlaceHolder = {};
