import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem, Media } from 'reactstrap';
import ResultsThumbnail from './ResultsThumbnail/ResultsThumbnail';
import MediaOverlayLink from '../MediaOverlayLink/MediaOverlayLink';
import { ApplicationVars, Endpoint } from '../../constants';
import { removeHtmlTags } from '../../utils/articleContent-utils';

const ResultsImageItem = ({ item }) => (
  <ListGroupItem className="ResultsImageItem ResultsList--item">
    <Media className="row">
      {item.image && (
        <Media left top className="col-12 col-sm-6 col-lg-4 result-item-thumbnail-wrapper">
          <MediaOverlayLink assembly={item}>
            <ResultsThumbnail
              alt={removeHtmlTags(item.title)}
              className="result-item-thumbnail image-item-thumbnail"
              isImageThumbnail
              src={
                Endpoint.CDN_URL + item.image.filename + ApplicationVars.RESULTS_LIST_THUMBNAIL_SIZE
              }
              title={removeHtmlTags(item.title)}
            />
          </MediaOverlayLink>
        </Media>
      )}
      <Media body className="col-12 col-sm-6 col-lg-8">
        <Media left top>
          <h2 className="media-heading ResultsImageItem--title">
            <MediaOverlayLink assembly={item}>
              <span dangerouslySetInnerHTML={{ __html: item.title }} />
            </MediaOverlayLink>
          </h2>
        </Media>
        <p
          className="ResultsImageItem--item-caption"
          dangerouslySetInnerHTML={{ __html: item.caption }}
        />
      </Media>
    </Media>
  </ListGroupItem>
);

ResultsImageItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

ResultsImageItem.defaultProps = {};

export default ResultsImageItem;
