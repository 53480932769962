import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { Levels } from '../../constants';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import InstAuthRoute from '../InstAuthRoute/InstAuthRoute';

const PrivateRoute = ({
  component: Component,
  isIndividualAuth,
  userData,
  level,
  ...restOfprops
}) => {
  const [showLoadingPage, setShowLoadingPage] = useState(true);

  /**
   * We want to show the loading page component temporarily (500 milliseconds) to give enough time for the user
   * to get authenticated in the background if refresh token is found is local storage.
   *
   * This will prevent the flashing/glimpse of the signin page during hard reloads on private routes.
   */
  useEffect(() => {
    let showLoadingPageTimer = null;

    if (isIndividualAuth) {
      setShowLoadingPage(false);
    } else {
      showLoadingPageTimer = setTimeout(() => setShowLoadingPage(false), 500);
    }

    return () => clearTimeout(showLoadingPageTimer);
  }, [isIndividualAuth]);

  if (showLoadingPage) {
    return <LoadingPage />;
  }

  return (
    <Route
      {...restOfprops}
      render={props =>
        isIndividualAuth ? (
          <InstAuthRoute
            level={level}
            isLoading={showLoadingPage}
            isAuth={isIndividualAuth}
            userData={userData}
            redirectPath={props.location.pathname}
          >
            <Component level={level} {...props} />
          </InstAuthRoute>
        ) : (
          <Redirect
            to={{
              pathname: `/${level}`,
              state: { from: props.location.pathname, userData },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isIndividualAuth: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
  location: PropTypes.shape(),
  level: PropTypes.string,
};

PrivateRoute.defaultProps = {
  level: Levels.ELEMENTARY,
};

export default PrivateRoute;
