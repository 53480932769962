// Políticas de conteúdo
import React from 'react';

const RequirementsPolicies = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Políticas de conteúdo</h2>
    <h3 className="toc-level-3 text-color--secondary">Citações</h3>
    <p>
      É permitido citar e utilizar o material do Britannica Escola se a fonte for mencionada
      corretamente. Todos os artigos incluem a citação já formatada e pronta para uso. Infelizmente,
      a Britannica não pode conceder permissão explícita para uso das imagens e diagramas que
      aparecem no Britannica Escola, pois os direitos autorais da maior parte desses materiais são
      detidos por terceiros. Caso lhe déssemos permissão para usá-los, estaríamos violando nossos
      próprios acordos de direitos autorais. Todas as imagens do Britannica Escola trazem a
      informação sobre o proprietário dos direitos autorais. Caso deseje utilizar alguma dessas
      imagens, por favor, entre em contato com o detentor dos direitos autorais para obter
      permissão. Todos os materiais deste portal estão protegidos pelas leis internacionais de
      direitos autorais e de marca registrada. É permitido imprimir ou baixar materiais do portal
      para seu uso pessoal não comercial. O uso desses materiais para fins comerciais é proibido,
      exceto em caso de permissões explícitas indicadas pelas leis pertinentes ou nos Termos de uso.
    </p>
    <p>
      Os usuários que desejarem incluir um link para o Britannica Escola em sua página da web podem
      baixar nosso ícone e acrescentá-lo a seu website:{' '}
      <a
        href="//britannica.com.br/Resources-Pt.html#logos"
        target="_blank"
        rel="noopener noreferrer"
      >
        Logotipos
      </a>
      .
    </p>
  </div>
);

export default RequirementsPolicies;
