import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListGroupItem, Media } from 'reactstrap';
import { Levels } from '../../../constants';

const SearchBoxMobileResultItem = ({ item, routePath, isInvertedTitle, level }) => (
  <ListGroupItem className="SearchBoxMobileResultItem ResultsList--item">
    <Media className="row">
      <Media body className="col-12">
        <Media
          className="result-item-title"
          left
          top
          tag={Link}
          to={`/${level}${routePath}${item.urlTitle}/${item.articleId}`}
        >
          <Media>
            <span
              dangerouslySetInnerHTML={{
                __html: isInvertedTitle ? item.invertedTitle : item.title,
              }}
            />
          </Media>
        </Media>
      </Media>
    </Media>
  </ListGroupItem>
);

SearchBoxMobileResultItem.propTypes = {
  item: PropTypes.shape().isRequired,
  routePath: PropTypes.string.isRequired,
  isInvertedTitle: PropTypes.bool,
  level: PropTypes.string,
};

SearchBoxMobileResultItem.defaultProps = {
  isInvertedTitle: true,
  level: Levels.ELEMENTARY,
};

export default SearchBoxMobileResultItem;
