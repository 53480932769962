import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';
import { levelsActions } from '../../store/levels/levels.dux';
import useWithStore from '../../hooks/useWithStore.hook';

const LazyLevelsPage = lazy(() => import('./LevelsPage'));

const LevelsPage = props => {
  const { dispatch } = useWithStore(() => ({}), {
    setSelectedLevel: levelsActions.setSelectedLevel,
  });

  React.useEffect(() => {
    dispatch.setSelectedLevel('');
  }, [dispatch]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <LazyLevelsPage {...props} />
    </Suspense>
  );
};

export default LevelsPage;
