import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyArticlePage = lazy(() => import('./ArticlePageContainer'));

const ArticlePage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyArticlePage {...props} />
  </Suspense>
);

export default ArticlePage;
