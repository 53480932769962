// Initial state
const initialState = {
  isAuthenticated: false,
  username: null,
  firstName: null,
  lastName: null,
  sub: null,
  error: null,
  responseReturned: false,
};

// Actions
const INDIVIDUAL_AUTH_SUCCESS = 'INDIVIDUAL_AUTH_SUCCESS';
const INDIVIDUAL_AUTH_FAIL = 'INDIVIDUAL_AUTH_FAIL';
const INDIVIDUAL_AUTH_SIGNOUT = 'INDIVIDUAL_AUTH_SIGNOUT';
const INDIVIDUAL_AUTH_INITIAL = 'INDIVIDUAL_AUTH_INITIAL';

// Action creators
export const individualAuthActions = {
  individualAuthSuccess: auth => ({
    type: INDIVIDUAL_AUTH_SUCCESS,
    payload: auth,
  }),

  individualAuthFail: error => ({
    type: INDIVIDUAL_AUTH_FAIL,
    payload: error,
  }),

  individualAuthSignout: () => ({
    type: INDIVIDUAL_AUTH_SIGNOUT,
  }),

  individualAuthInitial: payload => ({
    type: INDIVIDUAL_AUTH_INITIAL,
    payload,
  }),
};

// Reducer

export const individualAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        displayName: action.payload.displayName,
        email: action.payload.email,
        firstName: action.payload.firstName,
        individualId: action.payload.individualId,
        lastName: action.payload.lastName,
        roles: action.payload.roles,
        title: action.payload.title,
        username: action.payload.username,
        error: null,
        responseReturned: true,
      };
    case INDIVIDUAL_AUTH_FAIL:
      return {
        ...initialState,
        error: {
          status: action.payload.status,
          statusText: action.payload.statusText || action.payload.message,
        },
        responseReturned: true,
      };
    case INDIVIDUAL_AUTH_SIGNOUT:
      return {
        ...initialState,
        responseReturned: true,
      };
    case INDIVIDUAL_AUTH_INITIAL:
      return {
        ...initialState,
        ...action.payload,
      };
    default:
      return state;
  }
};
