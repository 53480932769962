import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert, Button } from 'reactstrap';
import './ErrorPrompt.scss';

const ErrorPrompt = props => {
  const { children, className, customErrorMessage, displayError, onReload } = props;

  return (
    <>
      {displayError ? (
        <Alert color="danger" className={classNames('ErrorPrompt', className)}>
          <p>{customErrorMessage}</p>
          {onReload && (
            <div className="ErrorPrompt__reload-button-wrapper">
              <hr />
              <Button color="quarternary" type="button" onClick={onReload}>
                Recarregar
              </Button>
            </div>
          )}
        </Alert>
      ) : (
        children
      )}
    </>
  );
};

ErrorPrompt.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element,
    PropTypes.bool,
  ]),
  className: PropTypes.string,
  displayError: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  onReload: PropTypes.func,
};

ErrorPrompt.defaultProps = {
  className: null,
  customErrorMessage: 'Ocorreu um erro', // <-- "An error has occurred"
  displayError: false,
  onReload: null,
};

export default ErrorPrompt;
