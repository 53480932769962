import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListGroupItem, Media } from 'reactstrap';

import { ApplicationVars, Endpoint } from '../../constants';
import useLevel from '../../hooks/useLevel.hook';

import ResultsThumbnail from './ResultsThumbnail/ResultsThumbnail';
import { removeHtmlTags } from '../../utils/articleContent-utils';

const ResultsArticleItem = ({ item, routePath, isInvertedTitle }) => {
  const { levelPath } = useLevel();

  const levelRoutePath = `/${levelPath}${routePath}`;

  return (
    <ListGroupItem className="ResultsArticleItem ResultsList--item">
      <Media className="row">
        {item.firstAssembly && (
          <Media
            left
            top
            tag={Link}
            to={`${levelRoutePath}${item.urlTitle}/${item.articleId}`}
            className="col-4 col-lg-2 d-none d-md-block result-item-thumbnail-wrapper"
          >
            <ResultsThumbnail
              className="result-item-thumbnail"
              src={
                Endpoint.CDN_URL +
                item.firstAssembly.image.filename +
                ApplicationVars.RESULTS_LIST_THUMBNAIL_SIZE
              }
              alt={removeHtmlTags(item.firstAssembly.caption || item.firstAssembly.title)}
              title={removeHtmlTags(item.firstAssembly.title)}
            />
          </Media>
        )}
        <Media body className="col-12 col-md-8 col-lg-10">
          <h2 className="media-heading">
            <Media
              className="result-item-title"
              tag={Link}
              to={`${levelRoutePath}${item.urlTitle}/${item.articleId}`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: isInvertedTitle ? item.invertedTitle : item.title,
                }}
              />
            </Media>
          </h2>
          <p dangerouslySetInnerHTML={{ __html: item.gist }} />
        </Media>
      </Media>
    </ListGroupItem>
  );
};

ResultsArticleItem.propTypes = {
  item: PropTypes.shape().isRequired,
  routePath: PropTypes.string.isRequired,
  isInvertedTitle: PropTypes.bool,
};

ResultsArticleItem.defaultProps = {
  isInvertedTitle: true,
};

export default ResultsArticleItem;
