import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseVideoPage = lazy(() => import('./BrowseVideoPageContainer'));

const BrowseVideoPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseVideoPage {...props} />
  </Suspense>
);

export default BrowseVideoPage;
