import React from 'react';
import PropTypes from 'prop-types';
import { sortableContainer } from 'react-sortable-hoc';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';
import { ListGroup } from 'reactstrap';
import LoadingContainer from '../Loading/LoadingContainer';
import { RoutePath } from '../../constants';
import ResultsArticleItem from './ResultsArticleItem';
import ResultsListNoResultsMessage from './ResultsListNoResultsMessage';
import './ResultsList.scss';

const ResultsList = props => {
  const {
    hasInfiniteScroll,
    hasMore,
    idType,
    isReverse,
    list,
    loadMore,
    pageStart,
    ResultsItemComp,
    routePath,
    threshold,
    useWindow,
    className,
    isInvertedTitle,
  } = props;

  if (hasInfiniteScroll) {
    return (
      <div className={classNames('ResultsList', className)}>
        {list ? (
          <InfiniteScroll
            className="ResultsList--infinite-scroll"
            hasMore={hasMore}
            initialLoad={false}
            isReverse={isReverse}
            loader={<LoadingContainer isLoading key="resultsList--spinner" />}
            loadMore={loadMore}
            pageStart={pageStart}
            threshold={threshold}
            useWindow={useWindow}
          >
            <ListGroup>
              {list.length ? (
                list.map((item, i) => (
                  <ResultsItemComp
                    {...props}
                    item={item}
                    routePath={routePath}
                    key={item[idType]}
                    isInvertedTitle={isInvertedTitle}
                    index={i}
                  />
                ))
              ) : (
                <ResultsListNoResultsMessage />
              )}
            </ListGroup>
          </InfiniteScroll>
        ) : (
          <LoadingContainer isLoading />
        )}
      </div>
    );
  }

  return (
    <div className={classNames('ResultsList', className)}>
      <LoadingContainer isLoading={!list}>
        {() => (
          <ListGroup className="ResultsList--group">
            {list.length ? (
              list.map((item, i) => (
                <ResultsItemComp
                  {...props}
                  item={item}
                  routePath={routePath}
                  key={item[idType]}
                  isInvertedTitle={isInvertedTitle}
                  index={i}
                />
              ))
            ) : (
              <ResultsListNoResultsMessage />
            )}
          </ListGroup>
        )}
      </LoadingContainer>
    </div>
  );
};

ResultsList.propTypes = {
  hasInfiniteScroll: PropTypes.bool,
  hasMore: PropTypes.bool,
  idType: PropTypes.string,
  isReverse: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape()),
  loadMore: PropTypes.func,
  pageStart: PropTypes.number,
  ResultsItemComp: PropTypes.func,
  routePath: PropTypes.string,
  threshold: PropTypes.number,
  useWindow: PropTypes.bool,
  className: PropTypes.string,
  isInvertedTitle: PropTypes.bool,
};

ResultsList.defaultProps = {
  hasInfiniteScroll: false,
  hasMore: false,
  idType: 'articleId',
  isReverse: false,
  list: null,
  loadMore: null,
  pageStart: 1,
  ResultsItemComp: ResultsArticleItem,
  routePath: RoutePath.ARTICLE.replace(':title/:articleId', ''),
  threshold: 250,
  useWindow: true,
  className: null,
  isInvertedTitle: true,
};

export default sortableContainer(ResultsList);
