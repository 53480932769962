import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyTestPage = lazy(() => import('./TestPage'));

const TestPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyTestPage {...props} />
  </Suspense>
);

export default TestPage;
