import React from 'react';
import { faAtom, faLandmark, faPassport, faCalculator } from '@fortawesome/pro-regular-svg-icons';
import { NavLink, Link } from 'react-router-dom';
import { ListGroupItem, CardBody, Card, BreadcrumbItem } from 'reactstrap';
import { RoutePath } from '../constants';

const metaTags = {
  default:
    'Nesta página, você pode pesquisar os artigos do Britannica Escola por temas específicos.',
  animalKingdom:
    'Nesta página, você pode procurar os animais pelo nome ou pelo hábitat onde vivem.',
};

const hiearchalNodes = [];

let browseIdsForPath = [];

function createSideNavigation(hiearchy, browseType = 'subject') {
  hiearchalNodes.push(
    <ListGroupItem key={hiearchy.browseId} id={`id-${hiearchy.browseId}`} className="active">
      {hiearchy.name}
    </ListGroupItem>
  );

  if (hiearchy.hasChildren) {
    checkChildren(hiearchy, 1, browseType, hiearchy.browseId);
  }

  return hiearchalNodes;
}

function checkChildren(hiearchy, level, browseType) {
  hiearchy.children.forEach(child => {
    let tmpLevel = level;

    browseIdsForPath = []; // Reset the path each time
    hiearchalNodes.push(
      <ListGroupItem key={child.browseId} id={`id-${child.browseId}`}>
        <Link to={getLinkForSideNavigation(browseType, hiearchy, child)}>
          {`Level ${tmpLevel}: ${child.name} -> ${child.browseId}`}
        </Link>
      </ListGroupItem>
    );

    if (child.hasChildren) {
      tmpLevel += 1;
      checkChildren(child, tmpLevel, browseType);
    }
  });
}

/**
 * @param  {object} themeHiearchy
 * @param  {string} browseId=null
 * @param  {string} subject=null
 * @param  {string} topic=null
 * @param  {object} weirdAnimals=null
 */
function constructPageTitle(
  themeHiearchy,
  browseId = null,
  subject = null,
  topic = null,
  weirdAnimals = null
) {
  // Weird anials represents the third level of hiearchy that animal kingdom can have.
  const title = [];

  if (+themeHiearchy.browseId === +browseId) {
    title.push(themeHiearchy.name);
  }

  if (subject) {
    title.push(themeHiearchy.children.find(item => +item.browseId === +subject).name);
  }

  if (topic && !weirdAnimals) {
    const subj = themeHiearchy.children.find(item => +item.browseId === +subject);

    title.push(subj.children.find(item => +item.browseId === +topic).name);
  }

  if (weirdAnimals) {
    if (weirdAnimals.children) {
      title.push(weirdAnimals.children.find(item => +item.browseId === +topic).name);
    }
  }

  return title.join(' - ');
}

function constructPageMetaDescription(
  prefix,
  type,
  themeHiearchy,
  browseId = null,
  subject = null,
  topic = null,
  weirdAnimals = null
) {
  // Weird animals represents the third level of hiearchy that animal kingdom can have.
  const description = [];

  if (+themeHiearchy.browseId === +browseId) {
    description.push(themeHiearchy.name);
  }

  if (subject) {
    description.push(themeHiearchy.children.find(item => +item.browseId === +subject).name);
  }

  if (topic && !weirdAnimals) {
    const subj = themeHiearchy.children.find(item => +item.browseId === +subject);

    description.push(subj.children.find(item => +item.browseId === +topic).name);
  }

  if (weirdAnimals) {
    if (weirdAnimals.children) {
      description.push(weirdAnimals.children.find(item => +item.browseId === +topic).name);
    }
  }

  return `${metaTags[type]} ${prefix}: ${description.join(' - ')}`;
}

function getLinkForSideNavigation(browseType, hiearchy, child) {
  browseIdsForPath.push(child.browseId);

  if (child.parent) {
    getParentBrowseIds(child);
  }

  return `/seção/${browseType}/${browseIdsForPath.reverse().join('/')}`;
}

function getParentBrowseIds(child) {
  if (child.parent) {
    browseIdsForPath.push(child.parent.browseId);
    getParentBrowseIds(child.parent);
  }
}

/**
 * @param  {Array} children
 * @param  {String} browseType
 * @param  {Int} subjectId=null
 */
function createChildrenOrSiblings(children, browseType, subjectId = null, groupId = null) {
  const tmpChildren = [];

  children.children.forEach(child => {
    const link = getLinkForItem(browseType, subjectId, groupId, child.browseId);

    tmpChildren.push(
      <ListGroupItem key={child.browseId} tag={NavLink} to={link}>
        {child.name}
      </ListGroupItem>
    );
  });

  return tmpChildren;
}

function getLinkForItem(browseType, subjectId, groupId, topicId) {
  if (groupId) {
    return `/seção/${browseType}/${groupId}/${subjectId}/${topicId}`;
  }

  if (subjectId) {
    return `/seção/${browseType}/${subjectId}/${topicId}`;
  }

  return `/seção/${browseType}/${topicId}`;
}

/**
 * @param  {Array} children
 * @param  {String} browseType
 * @param  {Int} subjectId=null
 */

function createMobileChildren(children, browseType, subjectId = null, groupId = null) {
  const tmpChildren = [];

  children.forEach(child => {
    const link = getLinkForItem(browseType, subjectId, groupId, child.browseId);

    tmpChildren.push(
      <Card
        key={child.browseId}
        // className={child.hasChildren ? 'has-children' : 'no-children'}
        tag={NavLink}
        to={link}
      >
        <CardBody>{child.name}</CardBody>
      </Card>
    );
  });

  return tmpChildren;
}

/**
 * @param  {Array} Hiearchy
 * @param  {Int} topicId=null
 */
function createBreadCrumbTrail(Hiearchy, topicId = null) {
  // The breadcrumb trail will always start with 'Início'
  // which translates roughly to 'Home' and links to the
  // home page. Knowing where we are in the breadcrumb tree
  // can be accomplished by using the original call to
  // fetchBrowseStructureByBrowseId which will return parents
  // and/or children of the current browserId. Since the
  // requested browserId is the active page we can ignore all
  // children for breadcrumbs and only worry about showing parents.
  // Parents are nested in the return structure, so a parent node,
  // could have another parent node inside of it.
  const tmpCrumbs = [];
  const activeTopic = topicId
    ? Hiearchy.children.find(child => child.browseId === parseInt(topicId, 10))
    : null;

  // If this is the jogos browse

  if (topicId) {
    tmpCrumbs.push(<BreadcrumbItem key={activeTopic.browseId}>{activeTopic.name}</BreadcrumbItem>);
  }

  tmpCrumbs.push(<BreadcrumbItem key={Hiearchy.browseId}>{Hiearchy.name}</BreadcrumbItem>);

  let x = { ...Hiearchy };

  while (x.parent) {
    tmpCrumbs.push(
      <BreadcrumbItem key={x.parent.browseId}>
        <Link to={`${x.parent.browseId}`}>{x.parent.name}</Link>
      </BreadcrumbItem>
    );
    x = x.parent;
  }

  tmpCrumbs.push(
    <BreadcrumbItem key={0}>
      <Link to={RoutePath.HOME}>Início</Link>
    </BreadcrumbItem>
  );

  return tmpCrumbs.reverse();
}

function fetchGameSubjectsForTiles() {
  return [
    {
      name: 'Matemática',
      themeId: '1400',
      defaultSubjectId: '1420',
      icon: faCalculator,
    },
    {
      name: 'Ciências',
      themeId: '2100',
      defaultSubjectId: '2110',
      icon: faAtom,
    },
    {
      name: 'História',
      themeId: '3100',
      icon: faLandmark,
    },
    {
      name: 'Portugês',
      themeId: '4100',
      icon: faPassport,
      defaultSubjectId: '4110',
    },
  ];
}

function fetchDefaultTopicsForAnimals() {
  return [
    {
      browseId: 2650150,
      defaultBrowseId: 2677522,
    },
    {
      browseId: 2692307,
      defaultBrowseId: 2673509,
    },
    {
      browseId: 2650152,
      defaultBrowseId: 2654584,
    },
    {
      browseId: 2684935,
      defaultBrowseId: 2655429,
    },
    {
      browseId: 2650160,
      defaultBrowseId: 2655546,
    },
    {
      browseId: 2650161,
      defaultBrowseId: 2650161,
    },
    {
      browseId: 2692309,
      defaultBrowseId: 2692309,
    },
    {
      browseId: 2650162,
      defaultBrowseId: 2650162,
    },
    {
      browseId: 2684936,
      defaultBrowseId: 2684936,
    },
    {
      browseId: 2650163,
      defaultBrowseId: 2650163,
    },
    {
      browseId: 2650164,
      defaultBrowseId: 2650164,
    },
    {
      browseId: 2650166,
      defaultBrowseId: 2650166,
    },
    {
      browseId: 2650168,
      defaultBrowseId: 2650168,
    },
    {
      browseId: 2650171,
      defaultBrowseId: 2650171,
    },
    {
      browseId: 2650172,
      defaultBrowseId: 2650172,
    },
    {
      browseId: 2650173,
      defaultBrowseId: 2650173,
    },
  ];
}

// This is for the animal browse only...

/**
 * @param  {string} groupName
 * @param  {string} subjectName
 * @param  {String} topicName
 */
function getBreadCrumbs(groupName, subjectName, topicName) {
  const tmpBreadCrumbs = [];

  tmpBreadCrumbs.push(<BreadcrumbItem key={groupName}>{groupName}</BreadcrumbItem>);
  tmpBreadCrumbs.push(<BreadcrumbItem key={subjectName}>{subjectName}</BreadcrumbItem>);

  if (topicName) {
    tmpBreadCrumbs.push(<BreadcrumbItem key={topicName}>{topicName}</BreadcrumbItem>);
  }

  return tmpBreadCrumbs;
}

function createMarkup(html) {
  return { __html: html };
}

function getBlurb(html) {
  return <span dangerouslySetInnerHTML={createMarkup(html)} />;
}

export {
  createChildrenOrSiblings,
  createMobileChildren,
  createBreadCrumbTrail,
  fetchGameSubjectsForTiles,
  fetchDefaultTopicsForAnimals,
  getBreadCrumbs,
  createMarkup,
  getBlurb,
  createSideNavigation,
  constructPageTitle,
  constructPageMetaDescription,
};
