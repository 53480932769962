import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazySharedCollectionPage = lazy(() => import('./SharedCollectionPage'));

const SharedCollectionPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazySharedCollectionPage {...props} />
  </Suspense>
);

export default SharedCollectionPage;
