// Consulta e suporte técnico
import React from 'react';

const ContactSupport = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Consulta e suporte técnico</h2>
    <h3 className="toc-level-3 mt-3">Para mais informações sobre a Britannica</h3>
    <a href="//britannica.com.br" rel="noopener noreferrer" target="_blank">
      http://britannica.com.br
    </a>
    <h3 className="toc-level-3 mt-3">Consulta e suporte técnico</h3>
    <a href="mailTo:brazil@eb.com">brazil@eb.com</a>
    <h3 className="toc-level-3 mt-3">Capacitação e desenvolvimento profissional</h3>
    <a href="//britannica.com.br/Training-Pt.html" rel="noopener noreferrer" target="_blank">
      http://britannica.com.br/Training-Pt.html
    </a>

    <h3 className="toc-level-3 mt-3">Sede central</h3>
    <address className="mt-2">
      <hgroup>
        <h6 className="text-color--natural-text">
          Encyclopædia Britannica, <abbr title="Incorporated">Inc.</abbr>
        </h6>
        <h6 className="text-color--natural-text">325 North LaSalle Street, Suite 200</h6>
        <h6 className="text-color--natural-text">
          Chicago, <abbr title="Illinois">IL</abbr> 60654
        </h6>
        <h6 className="text-color--natural-text">Estados Unidos</h6>
      </hgroup>
    </address>
  </div>
);

export default ContactSupport;
