import React from 'react';
import PropTypes from 'prop-types';
import './NavMenuItem.scss';

const NavMenuItem = props => {
  const { image, text, bgColor, isWhite, isMobile } = props;

  return (
    <>
      {isMobile ? (
        <figure className="mt-3">
          <div>{image}</div>
          <figcaption>{text}</figcaption>
        </figure>
      ) : (
        <div
          className="NavMenuItem"
          style={{
            backgroundColor: bgColor,
            textDecorationColor: isWhite ? 'white' : 'black',
          }}
        >
          <div>{image}</div>
          <div
            className="text"
            style={{
              color: isWhite ? 'white' : 'black',
            }}
          >
            {text}
          </div>
        </div>
      )}
    </>
  );
};

NavMenuItem.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  isWhite: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

export default NavMenuItem;
