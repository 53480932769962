import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseAnimalPage = lazy(() => import('./BrowseAnimalPageContainer'));

const BrowseAnimalPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseAnimalPage {...props} />
  </Suspense>
);

export default BrowseAnimalPage;
