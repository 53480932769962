// Jogos
import React from 'react';

const FunctionalityGames = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Jogos</h2>
    <p>
      Os jogos do Britannica Escola são divididos por área de conhecimento. As atividades permitem
      reforçar o aprendizado de maneira lúdica.
    </p>
    <p>
      Cada jogo tem três níveis de dificuldade. Para iniciar, escolha um dos níveis. Para voltar à
      tela de opções e selecionar um nível diferente, clique na flecha localizada no canto superior
      esquerdo.
    </p>
    <p>
      Para ver as instruções, clique no ponto de interrogação. O som pode ser ligado e desligado
      através do ícone do alto-falante. Nos jogos com contagem de tempo, clique no botão INICIAR
      para começar a jogar. É possível desativar e reativar a contagem de tempo através do botão
      LIG/DES. Também é possível pausá-la através do botão PAUSAR.
    </p>
    <p>
      Os jogos estão disponíveis apenas para computadores (desktops e notebooks). Não é possível
      acessá-los em dispositivos móveis (tablets e smartphones).
    </p>
  </div>
);

export default FunctionalityGames;
