import React from 'react';
import { store } from '../store/store';

// Store HOC connector for classical complements.
const useWithStore = (mapStateToProps = () => {}, mapActionsToProps) => {
  return WrappedComponent => props => {
    return (
      <store.Consumer>
        {([state, dispatch]) => {
          const mappedActionsToProps = {};

          if (mapActionsToProps !== undefined) {
            const mapActionsToPropsKeys = Object.keys(mapActionsToProps);

            // Link dispatch with actions and pass it back into the WrappedComponent
            for (let i = 0; i < mapActionsToPropsKeys.length; i += 1) {
              mappedActionsToProps[mapActionsToPropsKeys[i]] = (...theArgs) =>
                dispatch(mapActionsToProps[mapActionsToPropsKeys[i]](...theArgs));
            }
          }

          return (
            <WrappedComponent
              {...{ ...mapStateToProps(state), ...mappedActionsToProps, ...props }}
            />
          );
        }}
      </store.Consumer>
    );
  };
};

export default useWithStore;
