import React from 'react';
import PropTypes from 'prop-types';
import { compile } from 'path-to-regexp';
import { Route, Switch } from 'react-router-dom';
import { Levels, RoutePath } from '../../constants';

import Functionality from './HelpPageComponents/functionality';
import {
  FunctionalitySearchOptionsElementary,
  FunctionalitySearchOptionsMiddleHigh,
} from './HelpPageComponents/functionality-search-options';
import FunctionalityTools from './HelpPageComponents/functionality-tools';
import FunctionalityGames from './HelpPageComponents/functionality-games';
import FunctionalityWidget from './HelpPageComponents/functionality-widget';

import Contact from './HelpPageComponents/contact';
import ContactSupport from './HelpPageComponents/contact-support';
import ContactTraining from './HelpPageComponents/contact-training';

import Requirements from './HelpPageComponents/requirements';
import RequirementsTechnical from './HelpPageComponents/requirements-technical';
import RequirementsAccesibility from './HelpPageComponents/requirements-accessibility';
import RequirementsPolicies from './HelpPageComponents/requirements-policies';

const toPath = compile(RoutePath.BROWSE_HELP);

const HelpPageRoutes = ({ level }) => (
  <Switch>
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 1000 })}`}
      component={Functionality}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 1000, topic: 1001 })}`}
      component={
        level === Levels.ELEMENTARY
          ? FunctionalitySearchOptionsElementary
          : FunctionalitySearchOptionsMiddleHigh
      }
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 1000, topic: 1002 })}`}
      component={FunctionalityTools}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 1000, topic: 1003 })}`}
      component={FunctionalityGames}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 1000, topic: 1004 })}`}
      component={routeProps => <FunctionalityWidget {...routeProps} level={level} />}
    />

    <Route exact path={`/${level}${toPath({ theme: 1, subject: 2000 })}`} component={Contact} />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 2000, topic: 2001 })}`}
      component={ContactSupport}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 2000, topic: 2002 })}`}
      component={ContactTraining}
    />

    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 3000 })}`}
      component={Requirements}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 3000, topic: 3001 })}`}
      component={RequirementsTechnical}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 3000, topic: 3002 })}`}
      component={RequirementsAccesibility}
    />
    <Route
      exact
      path={`/${level}${toPath({ theme: 1, subject: 3000, topic: 3003 })}`}
      component={RequirementsPolicies}
    />
  </Switch>
);

HelpPageRoutes.propTypes = {
  level: PropTypes.string.isRequired,
};

export default HelpPageRoutes;
