import { useContext, useMemo } from 'react';
import { store } from '../store/store';

// Store hook connector for functional components.
const useWithStore = (mapStore = () => {}, mapActions) => {
  const [state, dispatcher] = useContext(store);
  const dispatch = useMemo(() => ({}), []);

  if (mapActions !== undefined) {
    const mapActionsKeys = Object.keys(mapActions);

    // Link dispatch with actions and return it back.
    for (let i = 0; i < mapActionsKeys.length; i += 1) {
      dispatch[mapActionsKeys[i]] = (...theArgs) =>
        dispatcher(mapActions[mapActionsKeys[i]](...theArgs));
    }
  }

  return { store: mapStore(state), dispatch };
};

export default useWithStore;
