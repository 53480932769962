const HelpHiearchy = {
  browseId: 1,
  name: 'Ajuda',
  hasChildren: true,
  children: [
    {
      browseId: 1000,
      hasChildren: true,
      name: 'Funcionalidades do portal',
      parent: {
        browseId: 1,
        name: 'Ajuda',
        hasChildren: true,
      },
      content: 'Hello, world.',
      children: [
        {
          browseId: 1001,
          hasChildren: false,
          name: 'Opções de pesquisa',
          content: 'Opções de pesquisa',
          parent: {
            browseId: 1000,
            name: 'Help Choice 1',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
        {
          browseId: 1002,
          hasChildren: false,
          name: 'Ferramentas',
          parent: {
            browseId: 1000,
            name: 'Help Choice 1',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
        {
          browseId: 1003,
          hasChildren: false,
          name: 'Jogos',
          parent: {
            browseId: 1000,
            name: 'Help Choice 1',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
        {
          browseId: 1004,
          hasChildren: false,
          name: 'Widget: Caixa de pesquisa do Britannica Escola',
          parent: {
            browseId: 1000,
            name: 'Help Choice 1',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
      ],
    },
    {
      browseId: 2000,
      hasChildren: true,
      name: 'Contato',
      content: 'Test 1004',
      parent: {
        browseId: 1,
        name: 'HelpChoices',
        hasChildren: true,
      },
      children: [
        {
          browseId: 2001,
          hasChildren: false,
          name: 'Consulta e suporte técnico',
          parent: {
            browseId: 2000,
            name: 'Help Choice 2',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
        {
          browseId: 2002,
          hasChildren: false,
          name: 'Capacitação e recursos',
          content: 'Content 2-1',
          parent: {
            browseId: 2001,
            name: 'Help Choice 2',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
      ],
    },
    {
      browseId: 3000,
      hasChildren: true,
      name: 'Requisitos técnicos e políticas de conteúdo',
      content: 'Test',
      parent: {
        browseId: 1,
        name: 'Ajuda',
        hasChildren: true,
      },
      children: [
        {
          browseId: 3001,
          hasChildren: false,
          name: 'Requisitos técnicos',
          parent: {
            browseId: 3000,
            name: 'Help Choice 2',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },

        {
          browseId: 3002,
          hasChildren: false,
          name: 'Acessibilidade ao portal',
          parent: {
            browseId: 3000,
            name: 'Help Choice 2',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
        {
          browseId: 3003,
          hasChildren: false,
          name: 'Políticas de conteúdo',
          parent: {
            browseId: 3000,
            name: 'Help Choice 2',
            hasChildren: true,
            parent: {
              browseId: 1,
              name: 'Ajuda',
              hasChildren: true,
            },
          },
        },
      ],
    },
  ],
};

export { HelpHiearchy as default };
