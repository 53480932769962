import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { Container, Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { Env, Levels } from '../../constants';

import './Footer.scss';
import escolaFtrBrand from '../../assets/images/escola_ftr_brand.svg';
// import escolaBrandingFooter from '../../assets/images/escola_branding_footer.png';

const FOOTER_LINKS = [
  { href: 'mailto:sugestoes_britannica@eb.com', label: 'Comentários' },
  { href: 'https://corporate.britannica.com/termos_de_uso.html', label: 'Copyright/Termos de uso' },
  { href: 'https://corporate.britannica.com/privacy.html', label: 'Política de privacidade' },
  { href: 'https://britannica.com.br/atividades-didaticas/', label: 'Recursos para o Professor' },
  { href: 'https://britannica.com.br/britannica-escola/', label: 'Saiba mais' },
];

const Footer = () => {
  const location = useLocation();

  return (
    <Navbar
      className={`Footer ${location.pathname === `/${Levels.ELEMENTARY}` ? '' : 'mt-5'}`}
      color="white"
      dark
      expand="lg"
      tag="footer"
    >
      <Container>
        <div className="footer-branding-container">
          <img className="logo" src={escolaFtrBrand} alt="Britannica logo" />
        </div>

        <div className="footer-links-container">
          <Nav navbar className="footer-links">
            {FOOTER_LINKS.map(({ href, label }, index) => (
              <NavItem key={href}>
                <NavLink target={index !== 0 ? '_blank' : ''} rel="noopener noreferrer" href={href}>
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <div className="footer-copyright text-lg-right">
            <span className="footer-copyright-text text-color--gray-600">
              © {new Date().getFullYear()} Encyclopædia Britannica, Inc. | v{Env.VERSION}
            </span>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default withRouter(Footer);
