import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class RouteChange extends Component {
  constructor(props) {
    super(props);

    this.unlistenFromOnRouteChange = () => {};

    this.onRouteChange = this.onRouteChange.bind(this);
  }

  componentDidMount() {
    const { history, location, onRouteChange } = this.props;

    if (onRouteChange !== null) {
      // Trigger callback on initial page load
      this.onRouteChange(location);

      // Trigger callback on subsequent route changes
      this.unlistenFromOnRouteChange = history.listen(this.onRouteChange);
    }
  }

  componentWillUnmount() {
    this.unlistenFromOnRouteChange();
  }

  onRouteChange(location, action) {
    const { onRouteChange } = this.props;

    if (typeof onRouteChange === 'function') {
      onRouteChange(location, action);
    } else {
      onRouteChange.forEach(callback => callback(location, action));
    }
  }

  render() {
    const { children } = this.props;

    return <>{children}</>;
  }
}

export default withRouter(RouteChange);

RouteChange.propTypes = {
  onRouteChange: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }).isRequired,
};

RouteChange.defaultProps = {
  children: '',
  onRouteChange: null,
};
