// Funcionalidades do portal
import React from 'react';

const Functionality = () => (
  <div className="container">
    <h2 className="toc-level-0 text-color--secondary">Funcionalidades do portal</h2>
  </div>
);

export default Functionality;
