import FetchManager from './FetchManager';
import { Endpoint, SearchType, APIKeys, ApplicationVars, Levels } from '../constants';

export default class ContentApi extends FetchManager {
  static fetchOptions = {
    method: 'GET',
    credentials: 'include',
    'Content-Type': 'application/json',
  };

  static async fetchArticle(level, articleId) {
    const fetchManager = new FetchManager();

    return fetchManager
      .fetch(`${Endpoint.BACKEND_API}/article/level/${level}/${articleId}`, {
        ...ContentApi.fetchOptions,
      })
      .catch(error => error);
  }

  static async fetchArticlePrint(level, articleId) {
    const fetchManager = new FetchManager();

    return fetchManager
      .fetch(`${Endpoint.BACKEND_API}/article/level/${level}/${articleId}/print`, {
        ...ContentApi.fetchOptions,
      })
      .catch(error => error);
  }

  fetchRandomArticle() {
    const level = Levels.ELEMENTARY;

    return this.fetch(`${Endpoint.BACKEND_API}/articles/${level}/random/preview`)
      .then(data => data?.[0])
      .catch(error => error);
  }

  fetchArticlesOfInterest(level, count = 1) {
    return this.fetch(
      `${Endpoint.BACKEND_API}/articles/${level}/random/preview?count=${count}`
    ).catch(error => error);
  }

  fetchRandomBiography(level, count = 1) {
    return this.fetch(`${Endpoint.BACKEND_API}/biography/${level}/random?count=${count}`).catch(
      error => error
    );
  }

  fetchVideoOfTheWeek(level) {
    return this.fetch(`${Endpoint.BACKEND_API}/video/${level}/videooftheweek`).catch(
      error => error
    );
  }

  fetchRandomVideo(level) {
    return this.fetch(`${Endpoint.BACKEND_API}/video/${level}/dyk/random`).catch(error => error);
  }

  fetchFolhaFeed() {
    return this.fetch(`${Endpoint.BACKEND_API}/feed/folha`).catch(error => error);
  }

  fetchJocaFeed() {
    return this.fetch(`${Endpoint.BACKEND_API}/feed/joca`).catch(error => error);
  }

  fetchBBCFeed() {
    return this.fetch(`${Endpoint.BACKEND_API}/feed/bbc`).catch(error => error);
  }

  fetchBrazilInFocus(level) {
    return this.fetch(`${Endpoint.BACKEND_API}/articles/${level}/brazilinfocus`).catch(
      error => error
    );
  }

  static async fetchBrowseStructureByBrowseId(browseId) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/subject/${browseId}?expandChildren=true`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static async fetchArticlesByBrowseId(
    browseId,
    pageNumber = 1,
    pageSize = ApplicationVars.PAGE_SIZE
  ) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/subject/${browseId}/articles?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static fetchAlphaStructureByTopic(
    topicPath,
    pageSize = ApplicationVars.PAGE_SIZE,
    groupSize = 1
  ) {
    const fetchManager = new FetchManager();

    let alphaStructureUrl = `${Endpoint.BACKEND_API}${topicPath}?pageSize=${pageSize}`;

    if (groupSize > 1) {
      alphaStructureUrl += `&groupSize=${groupSize}`;
    }

    return fetchManager.fetch(alphaStructureUrl, {
      ...ContentApi.fetchOptions,
    });
  }

  static fetchAlphaEntriesByTopic(
    topicPath,
    searchQuery,
    pageNumber,
    pageSize = ApplicationVars.PAGE_SIZE
  ) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}${topicPath}/entries?query=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static async fetchGameBrowseStructureByBrowseId(browseId) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/game/${browseId}?expandChildren=true`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static async fetchGameListByBrowseId(browseId, pageNumber = 1, pageSize = 1000) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/game/${browseId}/games?pageNumber=${pageNumber}&pageSize=${pageSize}&`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static async fetchVideosByBrowseId(
    browseId,
    pageNumber = 1,
    pageSize = ApplicationVars.PAGE_SIZE
  ) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/subject/${browseId}/assemblies?pageSize=${pageSize}&pageNumber=${pageNumber}`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  /**
   * @param  {Integer} browseId
   * @param  {} {returnfetch(`${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${browseId}`
   * @param  {} .then(response=>response.json(
   */

  static async fetchAnimalKingdomSubjectListByGroupId(browseId) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(`${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${browseId}`, {
      ...ContentApi.fetchOptions,
    });
  }

  /**
   * @param  {Integer} browseId
   * @param  {Integer} pageNumber=1
   * @param  {Integer} pageSize=ApplicationVars.PAGE_SIZE
   * @param  {} {returnfetch(
   * @param  {} .then(response=>response.json(
   */
  static async fetchAnimalKingdomArticlesByGroupId(
    browseId,
    pageNumber = 1,
    pageSize = ApplicationVars.PAGE_SIZE
  ) {
    const fetchManager = new FetchManager();

    return fetchManager.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${browseId}/articles?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        ...ContentApi.fetchOptions,
      }
    );
  }

  static async fetchAutocompleteSuggestions(
    searchType,
    searchQuery,
    level = Levels.ELEMENTARY,
    maxResults = 5
  ) {
    const fetchManager = new FetchManager();

    // based on searchType make the proper api calls.
    return searchType !== SearchType.DICTIONARY
      ? fetchManager.fetch(
          `${Endpoint.BACKEND_API}/search/article/${level}/autocomplete?pageSize=${maxResults}&query=${searchQuery}`,
          {
            ...ContentApi.fetchOptions,
          }
        )
      : fetchManager.fetch(
          `${Endpoint.BACKEND_API}/search/dictionary?autocomplete=true&query=${searchQuery}`, // it's only one endpoint for all levels
          {
            ...ContentApi.fetchOptions,
          }
        );
  }

  static async fetchAtlasLocationInformation(locations, level = 'anosiniciais') {
    const fetchManager = new FetchManager();

    return locations.length > 1
      ? fetchManager.fetch(
          `${Endpoint.BACKEND_API}/place/${level}?${locations[0].type}=${locations[0].iso}&${locations[1].type}=${locations[1].iso}`,
          {
            ...ContentApi.fetchOptions,
          }
        )
      : fetchManager.fetch(
          `${Endpoint.BACKEND_API}/place/${level}?${locations[0].type}=${locations[0].iso}`,
          {
            ...ContentApi.fetchOptions,
          }
        );
  }

  static async fetchPlaceByLatitudeAndLongitude(latitude, longitude) {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${APIKeys.GOOGLE_PLACES}&language=pt-BR&result_type=country|administrative_area_level_1`
    ).then(response => response.json());
  }

  static async fetchPlaceByPlaceId(placeId = 'ChIJmd5kZkdvABURmU4mUQdbKI0') {
    return fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?${placeId}$&key=${APIKeys.GOOGLE_PLACES}&language=pt-BR`
    ).then(response => response.json());
  }

  fetchAnimalKingdomSubjectListById(animalKingdomSubjectId, removeLinks = false) {
    return this.fetch(
      `${Endpoint.BACKEND_API}/browse/theme/animalkingdom/${animalKingdomSubjectId}?removeLinks=${removeLinks}`,
      {
        ...ContentApi.fetchOptions,
      }
    ).catch(error => error);
  }

  fetchSearchEntriesBySearchType(requestObj) {
    const {
      searchType,
      searchQuery,
      pageNumber = 1,
      pageSize = ApplicationVars.PAGE_SIZE,
      level = Levels.ELEMENTARY,
    } = requestObj;

    if (searchType !== 'dictionary') {
      const url = `${Endpoint.BACKEND_API}/search/${searchType}/${level}?query=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

      return this.fetch(url).catch(error => error);
    }

    const url = `${Endpoint.BACKEND_API}/search/${searchType}?query=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.fetch(url).catch(error => error);
  }

  fetchAssemblyByAssemblyId(assemblyId) {
    return this.fetch(`${Endpoint.BACKEND_API}/assembly/${assemblyId}`).catch(error => error);
  }
}

/*
  TODO: Need to use instance of the ContentApi instead of the class itself (A.B.)

  Export instance of ContentApi.
  For now we are exporting both the Class ContentApi (default) and an instance of it.
  Currently the instance is only being used in SearchResultsPageContainer & HomePageContainer.
  We are close to launching Escola and don't want to make a global change at the moment (Feb/4/19 A.B.).
  We will eventually use the instance of the ContentApi instead of the class itself to make use of the FetchManager that it extends off of.
*/
export const contentApi = new ContentApi();
