// Initial state
const initialState = {
  isAccessibility: false,
  videoAutoPlay: false,
};

// Actions
const TOGGLE_IS_ACCESSIBILITY = 'TOGGLE_IS_ACCESSIBILITY';
const SET_VIDEO_AUTO_PLAY = 'SET_VIDEO_AUTO_PLAY';

// Action creators
export const preferencesActions = {
  toggleIsAccessibility: () => ({
    type: TOGGLE_IS_ACCESSIBILITY,
  }),
  setVideoAutoPlay: autoplay => ({
    type: SET_VIDEO_AUTO_PLAY,
    payload: autoplay,
  }),
};

// Reducer

export const preferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_IS_ACCESSIBILITY: {
      const isAccessibility = !state.isAccessibility;

      return {
        ...state,
        isAccessibility,
      };
    }

    case SET_VIDEO_AUTO_PLAY: {
      return {
        ...state,
        videoAutoPlay: action.payload,
      };
    }

    default:
      return state;
  }
};
