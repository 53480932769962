import React from 'react';
import { Link } from 'react-router-dom';
import useLevel from '../../hooks/useLevel.hook';

const HeaderTitle = () => {
  const { levelPath, levelFullName } = useLevel();

  const Title = React.useMemo(() => {
    return (
      levelPath && (
        <Link className={`level-title ${levelPath}`} to={`/${levelPath}`}>
          {levelFullName}
        </Link>
      )
    );
  }, [levelPath, levelFullName]);

  return Title;
};

HeaderTitle.propTypes = {};

export default HeaderTitle;
