import { routeToLogin } from '@britannica/platform-client-lib';

/**
 *
 * @param {Response} response - Response object returned by a fetch call.
 * @returns { Response } - In case no error happens during the interceptor process,
 * it returns the response object back
 * @throws Will throw an error if the backend returns a 401 (Unauthorized) response
 * and it will redirect to the /failedlogin page
 */
const responseInterceptor = response => {
  if (response.status === 401) {
    const isIndividualApi =
      response.url.includes('collection') ||
      response.url.includes('individual') ||
      response.url.includes('deeplink');

    if (!isIndividualApi) {
      return routeToLogin(false, 'pt-BR');
    }
  }

  return response;
};

export default responseInterceptor;
