import useWithStore from './useWithStore.hook';

// Hook to get data related to the selected level
const useIsIndividualLoginEnabled = () => {
  const {
    store: { instAuth },
  } = useWithStore(state => ({
    instAuth: state.instAuth,
  }));

  const userData = instAuth.instUserData;

  const isIndividualLoginEnabled = userData?.preferences.suppressIndividualFeatures !== 'on';

  return isIndividualLoginEnabled;
};

export default useIsIndividualLoginEnabled;
