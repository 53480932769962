import useWithStore from './useWithStore.hook';

// Hook to get data related to the selected level
const useLevel = () => {
  const {
    store: { levelPath, levelFullName },
  } = useWithStore(
    state => ({
      levelPath: state.levels.levelPath,
      levelFullName: state.levels.levelFullName,
    }),
    {}
  );

  return { levelPath, levelFullName };
};

export default useLevel;
