import { LevelFullNames, Levels } from '../../constants';

// Initial state
const initialState = {
  levelPath: '',
  levelFullName: '',
};

// Actions
const SET_SELECTED_LEVEL = 'SET_LEVEL';

// Action creators
export const levelsActions = {
  setSelectedLevel: level => ({
    type: SET_SELECTED_LEVEL,
    payload: level,
  }),
};

// Reducer

export const levelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_LEVEL: {
      const level = action.payload;

      if (level === Levels.MIDDLE) {
        return {
          ...state,
          levelPath: Levels.MIDDLE,
          levelFullName: LevelFullNames.MIDDLE,
        };
      }

      if (level === Levels.HIGH) {
        return {
          ...state,
          levelPath: Levels.HIGH,
          levelFullName: LevelFullNames.HIGH,
        };
      }

      if (level === Levels.ELEMENTARY) {
        return {
          ...state,
          levelPath: Levels.ELEMENTARY,
          levelFullName: LevelFullNames.ELEMENTARY,
        };
      }

      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};
