import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseThemePage = lazy(() => import('./BrowseThemePageContainer'));

const BrowseThemePage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseThemePage {...props} />
  </Suspense>
);

export default BrowseThemePage;
