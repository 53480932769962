import { compile } from 'path-to-regexp';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { RoutePath } from '../../constants';
import AssemblyProp from '../../prop-types/AssemblyProp';
import ChildrenProp from '../../prop-types/ChildrenProp';

const toPath = compile(RoutePath.MEDIA_OVERLAY);

const MediaOverlayLink = ({ assembly, children, match: { url }, to }) =>
  assembly ? (
    <NavLink
      to={
        (to && to(url, toPath({ assemblyId: `${assembly.assemblyId}` }), assembly)) ||
        `${url}${toPath({ assemblyId: `${assembly.assemblyId}` })}`
      }
    >
      {children}
    </NavLink>
  ) : null;

MediaOverlayLink.propTypes = {
  assembly: AssemblyProp.isRequired,
  children: ChildrenProp.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  to: PropTypes.func,
};

MediaOverlayLink.defaultProps = {
  to: null,
};

export default withRouter(MediaOverlayLink);
