import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { store, combineReducers, initialState } from '../../store/store';

// Store provider to be used on root level of app.
const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(combineReducers, initialState);

  store.state = state;
  store.dispatch = dispatch;

  return <store.Provider value={[store.state, store.dispatch]}>{children}</store.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

StoreProvider.defaultProps = {
  children: null,
};

export default StoreProvider;
