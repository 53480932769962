import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazySamplePage = lazy(() => import('./SamplePageContainer'));

const SamplePage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazySamplePage {...props} />
  </Suspense>
);

export default SamplePage;
