import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyBrowseAlphaPage = lazy(() => import('./BrowseAlphaPageContainer'));

const BrowseAlphaPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyBrowseAlphaPage {...props} />
  </Suspense>
);

export default BrowseAlphaPage;
