import React from 'react';
import PropTypes from 'prop-types';
import AccordionNavigation from './AccordionNavigation';
import './AccordionNavigationContainer.scss';
import { BrowseType } from '../../constants';

class AccordionNavigationContainer extends React.Component {
  constructor(props) {
    super(props);

    const { hiearchy } = props;

    this.state = {
      /*
        On mobile the isRootNodeOpen will likely be false
      */
      isRootNodeOpen: true,
      /*
        hiearchy is the hiearchy of a single root node including the root node. hiearchyGroups, once
        processed does not contain the root node.
      */
      hiearchy,
      /*
        hiearchyGroups is an array of objects that include every node one layer under the root node
        each group has a single parent node, and can or cannot have children nodes. There should be no
        children of children. hiearchyGroups is recalculated whenever the root node changes, an AJAX
        call is made clicking on a root node will trigger a route change, and repopulate the hiearchy
        which is passed from a prop of the parent component.

        This makes it easier to treat each item in the navlist as it's own component.
      */
      hiearchyGroups: [],
      /*
        This is where the open/close state for each hiearchyGroups lives
      */
      groupOpenStateHash: {},
    };

    this.toggleRootNode = this.toggleRootNode.bind(this);
    this.toggleChildNode = this.toggleChildNode.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { hiearchy, match } = this.props;
    const previousTheme = prevProps.match.params.theme ? prevProps.match.params.theme : NaN;
    const currentTheme = match.params.theme ? match.params.theme : 0;

    if (
      !prevState.hiearchy ||
      previousTheme !== currentTheme ||
      JSON.stringify(prevState.hiearchy) !== JSON.stringify(hiearchy)
    ) {
      this.updateHiearchy(hiearchy);
    }
  }

  getHiearchyGroups(hiearchy) {
    const hiearchyGroups = [];

    if (hiearchy.hasChildren || hiearchy.children) {
      hiearchy.children.forEach(child => {
        hiearchyGroups.push(child);
      });
    }

    return hiearchyGroups;
  }

  getOpenState() {
    // Calculate the current open state for accordion
    // based off of url parameters.

    const { hiearchyGroups } = this.state;
    const tmpGroups = [];
    const tmpGroupsHash = {};
    const { match } = this.props;
    const { params } = match;

    hiearchyGroups.forEach(group => {
      if (
        +params.theme === +group.browseId ||
        +params.subject === +group.browseId ||
        +params.topic === +group.browseId
      ) {
        tmpGroupsHash[group.browseId] = true;
        tmpGroups.push({ [group.browseId]: true });
      } else {
        tmpGroupsHash[group.browseId] = false;
        tmpGroups.push({ [group.browseId]: false });
      }
    });

    return tmpGroupsHash;
  }

  updateHiearchy(hiearchy) {
    if (hiearchy) {
      this.setState({
        hiearchy,
        hiearchyGroups: this.getHiearchyGroups(hiearchy),
        groupOpenStateHash: this.getOpenState(),
      });
    }
  }

  toggleRootNode(e) {
    // Only used in mobile

    if (
      e.target.className === 'root d-md-none open list-group-item' ||
      e.target.className === 'root d-md-none closed list-group-item'
    ) {
      this.setState(prevState => ({
        isRootNodeOpen: !prevState.isRootNodeOpen,
      }));
    }
  }

  toggleChildNode(id) {
    /*--
      This fires whenever a top most node is clicked,
    --*/
    const { groupOpenStateHash, hiearchyGroups } = this.state;
    const groupOpenStateHashCopy = { ...groupOpenStateHash };

    hiearchyGroups.forEach(item => {
      if (item.browseId === +id) {
        groupOpenStateHashCopy[+item.browseId] = !groupOpenStateHashCopy[+id];
      } else {
        groupOpenStateHashCopy[+item.browseId] = false;
      }
    });

    this.setState({
      groupOpenStateHash: groupOpenStateHashCopy,
    });
  }

  render() {
    const { isRootNodeOpen, hiearchy, hiearchyGroups, groupOpenStateHash } = this.state;
    const { toggleRootNode, toggleChildNode } = this;
    const { match, browseType, mobileDropdownLabel, level } = this.props;
    const { params } = match;
    const { theme, subject, topic } = params;

    return (
      <AccordionNavigation
        isRootNodeOpen={isRootNodeOpen}
        toggleRootNode={toggleRootNode}
        rootNode={hiearchy}
        hiearchyGroups={hiearchyGroups}
        groupOpenStateHash={groupOpenStateHash}
        toggleChildNode={toggleChildNode}
        theme={theme}
        subject={subject}
        topic={topic}
        browseType={browseType}
        mobileDropdownLabel={mobileDropdownLabel}
        level={level}
      />
    );
  }
}

AccordionNavigationContainer.propTypes = {
  match: PropTypes.shape(),
  hiearchy: PropTypes.shape(),
  browseType: PropTypes.string,
  mobileDropdownLabel: PropTypes.string,
  level: PropTypes.string,
};

AccordionNavigationContainer.defaultProps = {
  hiearchy: null,
  browseType: BrowseType.GAMES,
  mobileDropdownLabel: 'Temas',
};

export default AccordionNavigationContainer;
