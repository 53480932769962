import React from 'react';

const ResultsListNoResultsMessage = () => (
  <li className="list-group-item">
    <p className="list-group-item-no-results-message">
      Não foi encontrado nenhum resultado para a sua pesquisa.
    </p>
  </li>
);

export default ResultsListNoResultsMessage;
