import FetchManager from './FetchManager';
import { Endpoint } from '../constants';
import { store } from '../store/store';

class MyBritannicaApi extends FetchManager {
  constructor() {
    super();
    this.dispatch = store.dispatch;
  }

  addCollection({ title, description, shared }) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection`, {
      method: 'POST',
      body: JSON.stringify({
        title,
        description,
        shared,
      }),
    });
  }

  addCollectionItem(collectionId, { contentType, contentId, note = null, tierId = 1 }) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection/${collectionId}/content/item`, {
      method: 'POST',
      body: JSON.stringify({
        contentType,
        contentId,
        note,
        tierId,
      }),
    });
  }

  deleteCollection(collectionId) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection/${collectionId}`, {
      method: 'DELETE',
    });
  }

  deleteCollectionItem(collectionId, favoriteId) {
    return this.fetch(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/content/item/${favoriteId}`,
      {
        method: 'DELETE',
      }
    );
  }

  getCollection(collectionId) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection/${collectionId}`);
  }

  getCollections() {
    return this.fetch(`${Endpoint.BACKEND_API}/collections`);
  }

  getSharedCollection(collectionId) {
    return this.fetch(`${Endpoint.BACKEND_API}/sharedCollection/${collectionId}`);
  }

  reorderCollectionItems(collectionId, reorderedFavoriteIds) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection/${collectionId}/reorderItems`, {
      method: 'PUT',
      body: JSON.stringify({
        reorderedFavoriteIds,
      }),
    });
  }

  updateCollection({ title, description, shared }, collectionId) {
    return this.fetch(`${Endpoint.BACKEND_API}/collection/${collectionId}`, {
      method: 'PUT',
      body: JSON.stringify({
        title,
        description,
        shared,
      }),
    });
  }

  updateCollectionItem(collectionId, favoriteId, note) {
    return this.fetch(
      `${Endpoint.BACKEND_API}/collection/${collectionId}/content/item/${favoriteId}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          note: note.length ? note : null,
        }),
      }
    );
  }
}

export default new MyBritannicaApi();
