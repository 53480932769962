import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyErrorPage = lazy(() => import('./ErrorPage'));

const ErrorPage = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyErrorPage {...props} />
  </Suspense>
);

export default ErrorPage;
