/* 
    Return the bolded version of the first sentence 
    of the first paragraph in a new section of the article.
  */
export const boldFirstParagraphSentence = html => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Find all the <p> tags inside the HTML doc
  const paragraphs = doc.querySelectorAll('p');

  paragraphs.forEach(paragraph => {
    const text = paragraph.innerHTML;

    const sentences = text.split('. '); // Split the text into sentences

    if (sentences.length > 0) {
      // Find the first sentence and wrap it in <strong>
      const firstSentence = `<strong>${sentences[0]}</strong>`;

      // Replace the original paragraph wit the new one
      paragraph.innerHTML = paragraph.innerHTML.replace(sentences[0], firstSentence);
    }
  });

  // Return the modified HTML
  return doc.querySelector('body').innerHTML;
};

export const removeHtmlTags = inputString => {
  return inputString?.replace(/<[^>]*>/g, '');
};
