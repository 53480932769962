import React, { lazy, Suspense } from 'react';
import LoadingPage from '../LoadingPage/LoadingPage';

const LazyDeepLinkHome = lazy(() => import('./DeepLinkHome'));

const DeepLinkHome = props => (
  <Suspense fallback={<LoadingPage />}>
    <LazyDeepLinkHome {...props} />
  </Suspense>
);

export default DeepLinkHome;
