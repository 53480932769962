import React from 'react';
import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import { RoutePath } from '../../constants';
import escolaHdrBranding from '../../assets/images/escola_hdr_branding.svg';

const HeaderLogo = () => {
  return (
    <NavbarBrand tag={Link} to={RoutePath.HOME}>
      <img className="logo" src={escolaHdrBranding} alt="Britannica logo" />
    </NavbarBrand>
  );
};

export default HeaderLogo;
