// Pretty much stolen from here: https://stackblitz.com/edit/reactstrap-v5-4rezrr?file=Example.js
import React from 'react';
import PropTypes from 'prop-types';
import { faLongArrowRight, faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { ListGroup, Collapse } from 'reactstrap';
import { BrowseType, Levels } from '../../constants';
import useWithStore from '../../hocs/useWithStore.hoc';

class ListGroupCollapse extends React.Component {
  constructor(props) {
    super(props);

    const { item, rootId } = props;

    this.state = { item, rootId };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item !== state.item) {
      return {
        item: props.item,
      };
    }

    return null;
  }

  render() {
    let isCurrentlyOpen = false;
    const { item, rootId } = this.state;
    const {
      toggleChildNode,
      groupOpenStateHash,
      theme,
      subject,
      topic,
      browseType,
      levelPath,
      level,
    } = this.props;

    let symbol;

    let btnClass = 'child list-group-item lvl-1';

    const level1Link =
      browseType === BrowseType.ANIMALS
        ? `/${levelPath}/seção/${browseType}/${theme}/${rootId}/${item.browseId}`
        : `/${levelPath}/seção/${browseType}/${rootId}/${item.browseId}`;

    try {
      isCurrentlyOpen = groupOpenStateHash[item.browseId];
      btnClass =
        +item.browseId === +theme || +item.browseId === +subject || +item.browseId === +topic
          ? 'child list-group-item lvl-1 active'
          : 'child list-group-item lvl-1';

      if (item.hasChildren) {
        if (isCurrentlyOpen) {
          symbol = (
            <FontAwesomeIcon
              className="open-menu fa-fw absolute-right"
              icon={faAngleUp}
              size="1x"
            />
          );
        } else {
          symbol = (
            <FontAwesomeIcon
              className="closed-menu fa-fw absolute-right"
              icon={faAngleDown}
              size="1x"
            />
          );
        }
      } else {
        symbol = '';
      }
    } catch (e) {
      console.log(e);
      console.log(item.browseId);
      console.log(groupOpenStateHash);
    }

    return (
      <ul>
        <li className={item.browseId === 3000 ? 'help-page-last-group' : null}>
          <Link
            to={level1Link}
            onClick={() => toggleChildNode(item.browseId, item)}
            className={btnClass}
          >
            {item.name}
            {symbol}
          </Link>
        </li>
        {item.hasChildren && (
          <ListGroup tag="li">
            <Collapse tag="ul" className="list-group--second-level" isOpen={isCurrentlyOpen}>
              {item.hasChildren &&
                item.children.map(node => (
                  <li
                    key={`${browseType}-${rootId}-${item.browseId}-${node.browseId}`}
                    className={item.browseId === 3000 ? 'help-page-last-group' : null}
                  >
                    {!(
                      node.browseId === 1003 &&
                      (level === Levels.MIDDLE || level === Levels.HIGH)
                    ) ? (
                      <Link
                        key={node.browseId}
                        tag="a"
                        to={`/${levelPath}/seção/${browseType}/${rootId}/${item.browseId}/${node.browseId}`}
                        className={
                          +topic === node.browseId
                            ? 'list-group-item lvl-2 selected'
                            : 'list-group-item lvl-2'
                        }
                      >
                        {+topic === node.browseId ? (
                          <FontAwesomeIcon
                            className="closed-menu fa-fw"
                            icon={faLongArrowRight}
                            size="1x"
                          />
                        ) : null}
                        {node.name}
                      </Link>
                    ) : null}
                  </li>
                ))}
            </Collapse>
          </ListGroup>
        )}
      </ul>
    );
  }
}

ListGroupCollapse.propTypes = {
  item: PropTypes.shape(),
  rootId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  groupOpenStateHash: PropTypes.shape(),
  toggleChildNode: PropTypes.func.isRequired,
  theme: PropTypes.string,
  topic: PropTypes.string,
  subject: PropTypes.string,
  browseType: PropTypes.string.isRequired,
  levelPath: PropTypes.string,
  level: PropTypes.string,
};

ListGroupCollapse.defaultProps = {
  item: null,
  rootId: '13001',
  groupOpenStateHash: null,
  theme: null,
  topic: null,
  subject: null,
  levelPath: '',
  level: '',
};

const mapStateToProps = state => ({
  levelPath: state.levels.levelPath,
});

export default useWithStore(mapStateToProps)(ListGroupCollapse);
